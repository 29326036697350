import React, { useState } from 'react'
import useUpdateData from "../../Hooks/useUpdateData"
import { useSelector } from 'react-redux';

const Footer = ({ selectedChat, scrollToBottom }) => {
  const { token } = useSelector(state => state.auth);

  const role = token?.occupation == 'Admin' ? 'Admin' : 'Company';
  const myID = token?.occupation == 'Admin' ? 'userID' : 'companyID';

  const { onSendMessage } = useUpdateData();

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState();

  const onSend = async (e) => {
    e?.preventDefault()
    if (!message) return;
    setLoading(true);
    const data = {
      message,
      timeStamp: parseInt(Date.now().toString()),
      senderID: token[myID],
      companyID: token?.occupation == 'Admin' ? selectedChat?.companyID : token?.companyID,
      ...(token?.occupation == 'Admin' || selectedChat?.isAdmin ? null : { employeeID: selectedChat?.userID }),
      seen: false
    }
    setMessage('')
    scrollToBottom();
    await onSendMessage(role, data, selectedChat?.isAdmin);
    setLoading(false);
  }

  return (
    <div className="chat-footer d-flex align-items-center">
      <div className="flex-grow-1 pe-2">
        <form onSubmit={onSend}>
          <div className="input-group">
            <input value={message} onChange={(e) => setMessage(e.target.value)} type="text" className="form-control" placeholder="Type a message" />
          </div>
        </form>
      </div>
      <div className="chat-footer-menu">
        <button onClick={onSend} disabled={loading} className="btn btn-primary" type="button"><i className="bi bi-send"></i></button>
      </div>
    </div>
  )
}

export default Footer