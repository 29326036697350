import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import Screen from "../../Components/Screen"
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import Bubble from "./Bubble"
import useLoadData from "../../Hooks/useLoadData"

const Conversation = () => {
  const bottomRef = useRef();
  const location = useLocation();

  // Redux
  const {token} = useSelector(state => state.auth);
  const {chats, messages} = useSelector(state => state.chat);

  const role = token?.occupation == 'Admin' ? 'Admin' : 'Company';
  const myID = token?.occupation == 'Admin' ? 'userID' : 'companyID';
  const chatId = `${token?.occupation == 'Admin' ? 'company' : 'user'}ID`;
  const messageId = `${token?.occupation == 'Admin' ? 'company' : 'employee'}ID`;

  const {loadChats} = useLoadData()

  // States
  const [selectedChat, setSelectedChat] = useState();

  const scrollToBottom = () => bottomRef.current?.scrollIntoView();

  useEffect(()=>{
    loadChats(role, token?.companyID);
  },[]);

  useEffect(() => scrollToBottom(),[selectedChat, messages])
  useEffect(() => {
    if(location.state?.chat) setSelectedChat(location.state?.chat);
  },[location])

  return (
    <Screen>
      <main className="page-content">
				<div className="chat-wrapper">
          <Sidebar 
            selectedChat = {selectedChat}
            onSelect = {setSelectedChat}
          />
          <Header selectedChat={selectedChat}/>
					<div className="chat-content" style={{overflow: 'scroll', overflowX: 'hidden'}}>
            {
              !selectedChat ? null :
              messages
              ?.filter(item=> (
                selectedChat?.isAdmin 
                ? item.companyID == token[myID] && (item.senderID == selectedChat[chatId] || item.companyID == token[myID])
                : item[messageId] == selectedChat[chatId]
              ))
              .sort((a,b)=>a.timeStamp - b.timeStamp)
              .map((item,index)=>{
                return (
                  <Bubble
                    key={index}
                    item={item}
                    isUs={token ? item.senderID == token[myID] : false}
                    selectedChat={selectedChat}
                  />
                )
              })
            }
            <div ref={bottomRef} />
					</div>
          <Footer selectedChat={selectedChat} scrollToBottom={scrollToBottom}/>
					<div className="overlay chat-toggle-btn-mobile"></div>
				</div>
			</main>
    </Screen>
  )
}

export default Conversation