import "./assets/css/style.css";

import React from "react";

import useSidebar from "../../Hooks/useSidebar";

import Header from "./Header";
const Home = ({ children }) => {
    const { show, hideSidebar } = useSidebar();

    return (
        <div onClick={show ? hideSidebar : null}>
            <Header />
            {show ? null : children}
        </div>
    );
};

export default Home;
