import React, { useState } from 'react'
import Form from './Form'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Redux
import { login } from '../../features/auth/slice';
import { handleShow } from '../../features/popup/slice';
import { onLogin } from '../../Api/auth';
import Popup from '../../Components/Popup';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState();
  const [remember, setRemember] = useState(false);

  const fields = [
    {
      label: 'Email',
      placeholder: 'Enter Email',
      icon: <i className="bi bi-envelope-fill"></i>,
      value: credentials.email,
      setValue: (e) => setCredentials({ ...credentials, email: e.target.value }),
      id: "inputEmailAddress",
      type: 'email'
    },
    {
      label: 'Password',
      placeholder: 'Enter Password',
      icon: <i className="bi bi-lock-fill"></i>,
      value: credentials.password,
      setValue: (e) => setCredentials({ ...credentials, password: e.target.value }),
      id: "inputPassword",
      type: 'password'
    },
  ]

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = await onLogin(credentials, remember);
      dispatch(login(token));
      navigate('/dashboard');
      // window.location.reload(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(handleShow({ screen: 'login', title: 'Unable to login', body: 'Incorrect Email or Password.' }));
      console.log(err);
    }
  }

  return (
    <main className="authentication-content mt-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-4 mx-auto">
            <div className="card shadow rounded-5 overflow-hidden">
              <div className="card-body p-4 p-sm-5">
                <h5 className="card-title">Welcome Back</h5>
                <p className="card-text">Sign in to you account</p>
                <div className="login-separater text-center mb-2">
                  <hr />
                </div>
                <form onSubmit={onSubmit} className="form-body">
                  <div className="row g-3">
                    <Form fields={fields} />
                    <div className="col-6">
                      <div className="form-check form-switch">
                        <input onChange={() => setRemember(!remember)} className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={remember} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Remember Me</label>
                      </div>
                    </div>
                    <div className="col-6 text-end">	<Link href="" to='/forgot'>Forgot Password ?</Link>
                    </div>
                    <div className="col-12">
                      <div className="d-grid">
                        <button type="submit" disabled={loading} className="btn btn-primary radius-30">{
                          loading ? 'Signing in...' : 'Sign In'
                        }</button>
                      </div>
                    </div>
                    <div className="col-12">
                      <p className="mb-0">Don't have an account yet? <Link href="" to='/signup'>Sign up here</Link></p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup screen={'login'} />
    </main>
  )
}

export default Login