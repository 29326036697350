import React from 'react'
import { Link, Navigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';

import { handleShow } from '../../features/popup/slice';
import Popup from '../../Components/Popup';

const Forgot = () => {
  const dispatch = useDispatch();
  const {token} = useSelector(state => state.auth);

  const onSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.inputEmailid.value
    try{
      await sendPasswordResetEmail(getAuth(), email);
      dispatch(handleShow({screen: 'forgot', title: 'Password reset mail has been sent'}));
    }catch(err){
      dispatch(handleShow({screen: 'forgot', title: 'Could not send reset email', body: err.message}));
    }
  }

  return token ? <Navigate to={'*'} /> :(
    
    <main className="authentication-content">
      <div className="container-fluid">
        <div className="authentication-card">
          <div className="card shadow rounded-5 overflow-hidden">
            <div className="row g-0">
              <div className="col-lg-6 d-flex align-items-center justify-content-center border-end">
                <img src="https://img.freepik.com/free-vector/man-thinking-concept-illustration_114360-7990.jpg?w=740&t=st=1662791646~exp=1662792246~hmac=a0cee1f7c7f898b2b47bfafc0c477f3f0ef9151e3488056c41b74ae4a3e6acb9" className="img-fluid" alt=""/>
              </div>
              <div className="col-lg-6">
                <div className="card-body p-4 p-sm-5">
                  <h5 className="card-title">Forgot Password?</h5>
                  <p className="card-text mb-5">Enter your registered email address to reset the password</p>
                  <form className="form-body" onSubmit={onSubmit}>
                      <div className="row g-3">
                        <div className="col-12">
                          <label htmlFor="inputEmailid" className="form-label">Email address</label>
                          <input type="email" className="form-control form-control-lg radius-30" id="inputEmailid" placeholder="Email address"/>
                        </div>
                        <div className="col-12">
                          <div className="d-grid gap-3">
                            <button type="submit" className="btn btn-lg btn-primary radius-30">Send</button>
                            <Link href="" to='/' className="btn btn-lg btn-light radius-30">Back to Login</Link>
                          </div>
                        </div>
                      </div>
                  </form>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup screen={'forgot'} />
    </main>
  )
}

export default Forgot