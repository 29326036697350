import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';

import { handleClose } from '../features/popup/slice';

const Popup = ({ screen, onSubmit, body }) => {
  const dispatch = useDispatch();
  const popup = useSelector(state => state.popup);
  const onClose = async () => {
    if (onSubmit)
      await onSubmit();
    dispatch(handleClose())
  };

  return (
    <Modal show={popup?.screen == screen} onHide={() => dispatch(handleClose())} centered>
      {
        !popup?.title ? null :
          <Modal.Header closeButton>
            <Modal.Title>{popup.title}</Modal.Title>
          </Modal.Header>
      }
      {
        body ? <Modal.Body>{body}</Modal.Body> :
          popup?.body ? <Modal.Body>{popup?.body}</Modal.Body> : null
      }
      {
        !onSubmit ? null :
          <Modal.Footer>
            <Button variant="primary" onClick={onClose}>
              Ok
            </Button>
          </Modal.Footer>
      }
    </Modal>
  );
}

export default Popup;