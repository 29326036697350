import React from "react";

const Unauthorized = () => {
  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="card p-5 rounded-5">
        <h1 class="card-title text-success text-center">
          This page does not exist
        </h1>
      </div>
    </div>
  );
};

export default Unauthorized;
