import { useSelector, useDispatch } from 'react-redux';

// Redux
import { handleHide, handleShow } from '../features/home/slice';

export default () => {
    const dispatch = useDispatch();
    const { show } = useSelector(state => state.sidebar);

    const showSidebar = (e) => {
        e.stopPropagation();
        dispatch(handleShow())
    };
    const hideSidebar = () => dispatch(handleHide());

    return {
        show,
        showSidebar,
        hideSidebar,
    }
}