import React from 'react'
import { useSelector } from 'react-redux';
import { useLocation, Link } from "react-router-dom";

import useSidebar from '../../Hooks/useSidebar'


const Sidebar = () => {
    const { token } = useSelector((state) => state.auth);
    const location = useLocation();
    const { show } = useSidebar();
    return !show ? false : (
        <div className='sidebar'>
            <ul >
                <li>
                    <a className="nav-link scrollto active" href="#hero">
                        {location.pathname.toLocaleUpperCase().substring(1)}
                    </a>
                </li>
                <li>
                    <a className="nav-link scrollto" href="/#about">
                        About
                    </a>
                </li>
                <li>
                    <a className="nav-link scrollto" href="/#services">
                        Services
                    </a>
                </li>
                <li>
                    <a className="nav-link scrollto " href="/#why-us">
                        How it works?
                    </a>
                </li>
                <li>
                    <a className="nav-link scrollto" href="/#pricing">
                        Pricing
                    </a>
                </li>
                <li>
                    <a className="nav-link scrollto" href="/#faq">
                        Support
                    </a>
                </li>
                <li>
                    <a className="nav-link getstarted" href={token ? "/dashboard" : "/login"}>
                        {token ? "Dashboard" : "Login"}
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Sidebar