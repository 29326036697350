import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        show: false,
    },
    reducers: {
        handleShow: (state) => {
            state.show = true;
        },
        handleHide: (state, action) => {
            state.show = false;
        }
    }
})

export const { handleShow, handleHide } = sidebarSlice.actions;

export default sidebarSlice.reducer;