import React from 'react'

import NavbarItem from './NavbarItem';
import logo from '../Assets/logo.svg';
import logo2 from '../Assets/Logo_TT.svg';
import logo3 from '../Assets/LogoOnly.png';
import text from '../Assets/text.png';
// import logo2 from '../Assets/White Time Tracker Logo Font.svg';
import { useSelector } from 'react-redux';

const Navbar = () => {
  const { token } = useSelector(state => state.auth);

  const companies = [
    {
      icon: <i className="bx bx-building" />,
      label: 'Company Menu',
      menu: [
        token?.occupation == 'Admin' ?
          {
            icon: <i className="bi bi-circle" />,
            label: 'All Companies',
            to: '/companies'
          } : null,
        token?.occupation == 'Owner' ?
          {
            icon: <i className="bi bi-circle" />,
            label: 'Company Schedule',
            to: '/schedule'
          } : null,
      ]
    },
    {
      icon: <i className="bx bx-message" />,
      label: token?.occupation == 'Owner' ? 'Chat with Admin' : 'Chat',
      to: '/conversation'
    },
  ];

  const employees = token?.occupation == 'Owner' ? [
    {
      icon: <i className="bx bx-building" />,
      label: 'Employee Menu',
      menu: [
        {
          icon: <i className="bi bi-circle" />,
          label: 'All Employees',
          to: '/employees'
        },
        {
          icon: <i className="bi bi-circle" />,
          label: 'New Employee',
          to: '/new_employee'
        },
      ]
    },
    {
      icon: <i className="bx bx-message" />,
      label: 'Chat with Employee',
      to: '/conversation'
    },
  ] : null;


  return (
    <aside className="sidebar-wrapper" data-simplebar="true">
      <div className="sidebar-header d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <img src={logo3} className="logo-icon" alt="logo icon" />
          <img src={text} className="logo-text w-50" alt="logo icon" />
        </div>
        <div className="toggle-icon ms-auto"> <i className="bi bi-list"></i></div>
      </div>
      <ul className="metismenu" id="menu">
        <NavbarItem
          item={{
            label: 'Dashboard',
            icon: <i className="bx bx-home"></i>,
            to: '/dashboard'
          }}
        />
        <li className="menu-label">Companies</li>
        {
          companies?.map((item, index) => <NavbarItem key={index} item={item} />)
        }
        {
          !employees ? null : <>
            <li className="menu-label">Employees</li>
            {
              employees?.map((item, index) => <NavbarItem key={index} item={item} />)
            }
          </>
        }
      </ul>
    </aside>
  )
}

export default Navbar