import React from 'react'
import { useSelector } from 'react-redux'

const Header = ({selectedChat}) => {
  return (
    <div className="chat-header d-flex align-items-center">
      <div className="chat-toggle-btn"><i className='bx bx-menu-alt-left'></i>
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {
          !selectedChat ? null :
          <img src={selectedChat?.imageURL} style={{marginRight: 20}} className="rounded-circle" alt="" width="50" height="50"/>
        }
        <h4 className="mb-1 font-weight-bold">{selectedChat?.name}</h4>
      </div>
    </div>
  )
}

export default Header