import './assets/css/style.css';

import React from 'react'
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Screen from "./Screen";

const Terms = () => {

  return (
    <Screen>

      <main id="main">

        <section className="breadcrumbs">
          <div className="container">

            <div className="d-flex justify-content-between align-items-center">
              <h2>Terms & Conditions</h2>
              <ol>
                <li><Link to="/">Home</Link></li>
                <li>Terms & Conditions</li>
              </ol>
            </div>

          </div>
        </section>

        <section className="inner-page">
          <div className="container">
            <h3><strong>Terms of condition</strong></h3>
            <p>Time Tracker ("Time Tracker", "we", "us" and terms of similar meaning) provides this web site and the services provided by or through this web site to you subject to these terms and conditions of use (these "Terms").</p>
            <p>In these terms we call this web site, any successor web sites (together, the "Site") and the software we provide the "Application". The Application includes (i) your use of the Time Tracker Mobile Application, and the use of the Time Tracker Mobile Application by third parties authorized through your Time Tracker account to use your User Content (as defined below) through the Time Tracker Mobile Application, and (ii) your use of The Time Tracker Add-ons, and any software or services provided by third parties through The Time Tracker site for use with the Application (in these Terms we call these third party software or services "Add-ons"). We refer to the services provided by the Application as the "Services".</p>
            <p>Please read these Terms carefully before using the Services. By accessing or using the Services you agree to be legally bound by these Terms and all terms, policies and guidelines incorporated by reference in these Terms. If you do not agree with these Terms in their entirety, you may not use the Services.</p>
            <p>The Services are not intended to be used by children. You must be at least of the age of majority to use the Services.</p>
            <p>In these Terms, our customers and their staff members to whom they give "administrator" access to their Time Tracker account are called "Subscribers", and our customers’ users who use the Services (for example, employees that track their time or other members of the customer's organization) are called "Members". In these Terms users of the Services, whether they are Subscribers, Members, members of the Time Tracker forum, people who post comments on the Time Tracker blog, or casual browsers of the Site, are called "Users".</p>
            <p>Time Tracker reserves the right to change or modify any of the terms and conditions contained in these Terms, or any policy or guideline applicable to the Services, at any time and in its sole discretion. If we do so, we will notify you at the email address you provide in your registration information, if any. If you do not agree with the changes, you can cancel your account with us without further obligation, except for the amount due for the balance of the billing period in which you cancel your account (if your billing period is monthly, we will prorate your account to the nearest month-end after cancellation). Unless otherwise specified, any changes or modifications will be effective immediately upon posting of the revisions on the Site, and your continued use of the Services after such time will constitute your acceptance of such changes or modifications. You should from time to time review the Terms and any policies and documents incorporated in them to understand the terms and conditions that apply to your use of the Services. The Terms will always show the ‘last updated’ date at the top. If you do not agree to any amended Terms, you must stop using the Services. If you have any questions about the Terms, please email us at support@MyTimeTracker.app.</p>
            <p>The Services are for your own use only. You may not resell, lease or provide them in any other way to anyone else, except as expressly permitted through the Application.</p>
            <ol>
              <li>
                <h3>Privacy Policy</h3>
                <p>Please refer to Time Tracker's privacy policy, available at (the "Privacy Policy") for information on how Time Tracker collects, uses and discloses personally identifiable information from its users. By using the Services you agree to our use, collection and disclosure of personally identifiable information in accordance with the Privacy Policy.</p>
              </li>
              <li>
                <h3>Registration Data; Account Security</h3>
                <p>If you register for an account on the Services, you agree to (a) provide accurate, current and complete information as may be prompted by any registration forms on the Services ("Registration Data"); (b) maintain the security of your password; (c) maintain and promptly update the Registration Data, and any other information you provide to Time Tracker, and to keep it accurate, current and complete; and (d) accept all risks of unauthorized access to the Registration Data and any other information you provide to Time Tracker. You are responsible for all activity on your Time Tracker account, and for all charges incurred by your Time Tracker account.</p>
              </li>
              <li>
                <h3>Fees; Charges; Taxes</h3>
                <p>Fees and any other charges for the use of the Application and for any Add-ons are described on the Site. They may change from time to time. If we change them, we will give you at least 30 days’ notice. If they do change, your continued use of the Application or the Add-ons, as the case may be, after the change indicates your agreement with the new fees and charges after the effective date of the change. Any change to fees and other charges will not be applicable to the billing period in which the change occurs.
                  All sales are final. Payments made are Non-Refundable.
                  You are responsible for all taxes applicable to the fees and charges in any applicable jurisdiction.
                </p>
              </li>
              <li>
                <h3>Ownership, Copyright and Trademarks</h3>
                <p>In these Terms the content available through the Services, including all information, data, logos, marks, designs, graphics, pictures, sound files, other files, and their selection and arrangement, is called "Content". Content provided by Users, whether they are Subscribers, Members or other Users, is called "User Content". For example, data about its Members and about its timesheet entries that a Subscriber provides to the Services is User Content of that Subscriber, data about its Members that a Member provides to the Services is User Content of that Member, and Content that a User enters into the Time Tracker forum or enters as a comment on the Time Tracker blog is User Content of the User.</p>
                <p>User Content is that User’s property. Time Tracker’ only right to that User Content is the limited licenses to it granted in these Terms. Those licenses are described in Section 5 and Section 16 of these Terms.</p>
                <p>User Content is that User’s property. Time Tracker’ only right to that User Content is the limited licenses to it granted in these Terms. Those licenses are described in Section 5 and Section 16 of these Terms.</p>
                <p>Your User Content is your responsibility. We have no responsibility or liability for it, or for any loss or damage your User Content may cause to you or other people. Although we have no obligation to do so, we have the absolute discretion to remove, screen or edit without notice any User Content posted or stored on the Services, and we may do this at any time and for any reason. You are solely responsible for maintaining copies of and replacing any User Content you post or store on the Services. If you authorize third parties to access your User Content through the Services, including through the Time Tracker Mobile Application or an Add-on, you agree that we are permitted to provide to them the User Content, and also agree that we have no responsibility or liability for their use of such User Content</p>
              </li>
              <li>
                <h3>Your Limited License of Your User Content to Time Tracker</h3>
                <p>We do not claim any ownership interest in your User Content, but we do need the right to use your User Content to the extent necessary to operate the Site and provide the Services, now and in the future. For example, if you leave comments on the Time Tracker blog or posts on the Time Tracker forum, we need your license to display that User Content on the Services, and we need the right to sublicense that User Content to our other Users so that they can view that User Content. If you are a Subscriber and enter timesheet data in relation to a Member and configure your account to display timesheet data to your Members, we need your license to that User Content to use it in the operation of the Services, and we need the right to sublicense that User Content to your Members for that purpose.</p>
                <p>Therefore, by posting or distributing User Content to or through the Services, you (a) grant Time Tracker and its affiliates and subsidiaries a non-exclusive, royalty-free, transferable right to use, display, perform, reproduce, distribute, publish, modify, adapt, translate, and create derivative works from such User Content, in the manner in and for the purposes for which the Services from time to time use such User Content; (b) represent and warrant that (i) you own and control all of the rights to the User Content that you post or otherwise distribute, or you otherwise have the lawful right to post and distribute that User Content, to or through the Services; and (ii) the use and posting or other transmission of such User Content does not violate these Terms and will not violate any rights of or cause injury to any person or entity.</p>
                <p>If your User Content is intended for the use of other Users (for example, if you provide reports to Members or authorize third parties to access your User Content through the Time Tracker Mobile Application), you also grant us and our affiliates and subsidiaries a non-exclusive, royalty-free, transferable right to sublicense such User Content to such Users for their use in connection with their use of the Services, as described in Section 5 of these Terms.</p>
                <p>These licenses from you are non-exclusive because you have the right to use your User Content elsewhere. They are royalty-free because we are not required to pay you for the use of your User Content on the Services. And they are transferable because we need the right to transfer these licenses to any successor operator of the Services. Our rights to "modify, adapt, translate, and create derivative works from" are necessary because the normal operation of the Services does this to your User Content when it processes it for use in the Services.</p>
              </li>
              <li>
                <h3>Our Limited License of Content to You</h3>
                <p>Time Tracker grants you a limited, revocable, non-exclusive, non-sublicensable license to access the Services and to view, copy and print the portions of the Content available to you through the Services. Such license is subject to these Terms, and specifically conditioned upon the following: (i) you may only view, copy and print such portions of the Content for your own use; (ii) you may not modify or otherwise make derivative works of the Content, or reproduce, distribute or display the Content (except for page cacheing) except as expressly permitted in these Terms; (iii) you may not remove or modify any copyright, trademark, or other proprietary notices that have been placed in the Content; (iv) you may not use any data mining, robots or similar data gathering or extraction methods; and (v) you may not use the Services or the Content other than for their intended purpose.</p>
                <p>Except as expressly permitted above, any use of any portion of the Content without the prior written permission of its owner is strictly prohibited and will terminate the license granted in this Section, this Agreement and your account with us. Any such unauthorized use may also violate applicable laws, including without limitation copyright and trademark laws. Unless explicitly stated herein, nothing in these Terms may be construed as conferring any license to intellectual property rights, whether by estoppel, implication or otherwise. The license in this Section is revocable by Time Tracker at any time.</p>
                <p>Your use of any Add-on is governed by the End User License Agreement that applies to that Add-on, and not by this Section of the Terms. That End User License Agreement is an agreement between you and the provider of that Add-on, and you acknowledge that that End User License Agreement is a binding agreement between you and that provider. Your use of the Service through an Add-on, including any Content that may be delivered to you through the Add-on, is subject to this Section of the Terms.</p>
                <p>You represent and warrant that your use of the Services and the Content will be consistent with this license and will not infringe or violate the rights of any other party or breach any contract or legal duty to any other parties, or violate any applicable law.</p>
                <p>To request permission for uses of Content not included in this license, you may contact Time Tracker at the address set out at the bottom of these Terms.</p>
              </li>
              <li>
                <h3>Use of Interactive Areas and the Services</h3>
                <p>The Services may include discussion forums, bulletin boards, review services or other forums in which you or third parties may post reviews or other content, messages, materials or other items on the Services ("Interactive Areas"). If Time Tracker provides such Interactive Areas, you are solely responsible for your use of such Interactive Areas and use them at your own risk. User Content submitted to any public area of the Services will be considered non-confidential. You agree not to post, upload to, transmit, distribute, store, create or otherwise publish through the Services any of the following:</p>
                <ul>
                  <li>Any message, data, information, text, music, sound, photos, graphics, code or other material that is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, fraudulent or otherwise objectionable;</li>
                  <li>Content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, or that would otherwise create liability or violate any local, state, national or international law;</li>
                  <li>Content that may infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary right of any party;</li>
                  <li>Content that impersonates any person or entity or otherwise misrepresents your affiliation with a person or entity;</li>
                  <li>Unsolicited promotions, political campaigning, advertising or solicitations;</li>
                  <li>Private information of any third party, including, without limitation, addresses, phone numbers, email addresses and credit card numbers, unless that third party has expressly consented to such use;</li>
                  <li>Viruses, corrupted data or other harmful, disruptive or destructive files;</li>
                  <li>Content that is unrelated to the topic of the Interactive Area(s) in which such Content is posted; or</li>
                  <li>Content that, in the sole judgment of Time Tracker, is objectionable or which restricts or inhibits any other person from using or enjoying the Interactive Areas or the Services, or which may expose Time Tracker or its affiliates or its users to any harm or liability of any type.</li>
                </ul>
                <p>Finally, Time Tracker has a "zero-tolerance" policy towards SPAM: you may not use the Interactive Areas or the Services generally to send commercial or other messages to any third-party if those messages are not solicited, authorized or welcomed by the third-party, and in your use of the Services you must comply with all applicable laws, including laws that apply in any jurisdiction to SPAM and marketing practices, and with any applicable marketing association guidelines on ethical marketing practices.</p>
                <p>Any use of the Interactive Areas or other portions of the Services in violation of the foregoing violates these Terms and may result in, among other things, termination or suspension of your rights to use the Interactive Areas and/or the Services.</p>
              </li>
              <li>
                <h3>Providing a Reliable and Secure Service</h3>
                <p>If you have spent any time reviewing the Services, you will hopefully have noticed that we take reliability and security seriously. We put a great deal of effort into ensuring that our service operates all the time, and that it is a secure environment for your data. We use what we believe to be "best-of-className" hosting services and security technologies and services that we believe provide you with a secure and safe environment.</p>
                <p>For example, to safeguard credit card information, Time Tracker doesn't store your credit card numbers. Credit card numbers are sent in an encrypted fashion to credit card processors that adhere to industry standard PCI compliant procedures to encrypt, secure and store credit card information. Additionally, Time Tracker uses restrictive firewalls to protect stored data and uses 128 bit SSL certificates to encrypt data transferred between you and the Services.</p>
                <p>However, no system is perfectly secure or reliable, the Internet is an inherently insecure medium, and the reliability of hosting services, Internet intermediaries, your Internet service provider, and other service providers cannot be assured. When you use Time Tracker, you accept these risks, and the responsibility for choosing to use a technology that does not provide perfect security or reliability. In particular, Subscribers who enable auto-billing and choose to store their credit card information accept these risks to the security of that credit card information. When storing credit card information using the Time Tracker recurring billing module Subscribers acknowledge that they are aware of, and accept as satisfactory, Time Tracker's credit card protection procedures. Ultimately, credit card data is provided by Subscribers, and they are responsible for its protection.</p>
                <p>Finally, Time Tracker provides the Time Tracker Mobile Application to facilitate the use of the Services with mobile devices. If a third party is authorized through your Time Tracker account to have access to your User Content through the Time Tracker Mobile Application, we cannot control and are not responsible or liable for the third party’s use of your User Content.</p>
              </li>
              <li>
                <h3>No Responsibility for Third-Party Material</h3>
                <p>The Site may contain links to third-party Web sites ("Third-Party Sites") and third-party content ("Third-Party Content") as a service to those interested in this information, including Add-ons, payment processors and other payment intermediaries that you may use in connection with your use of the Services. You use links to Third-Party Sites and any Third-Party Content or service provided there, at your own risk.</p>
                <p>Time Tracker makes no claim or representation regarding Third-Party Content or Third-Party Sites, and provides them or links to them only as a convenience. Inclusion in the Services of a link to a Third-Party Site or Third-Party Content does not imply Time Tracker's endorsement, adoption or sponsorship of, or affiliation with, such Third-Party Site or Third-Party Content. Time Tracker accepts no responsibility for reviewing changes or updates to, or the quality, content, policies, nature or reliability of, Third-Party Content, Third-Party Sites, or Web sites linking to the Services. When you leave the Services, our terms and policies no longer govern. You should review applicable terms and policies, including privacy and data gathering practices, of any Third-Party Site, and should make whatever investigation you feel necessary or appropriate before proceeding with any transaction with any third party.</p>
                <p>The provider of each Add-on is solely responsible for that Add-on, the content therein, any warranties to the extent that such warranties have not been disclaimed, any training, support or maintenance for the Add-on, and any claims that you or any other party may have relating to that Add-on or your use of that Add-on. You acknowledge that you are purchasing the license to each Add-on from the provider of that Add-on; Time Tracker is acting as agent for the provider in providing each such Add-on to you; Time Tracker is not a party to the license between you and the provider with respect to that Add-on; and Time Tracker is not responsible for that Add-on, the content therein, or any warranties or claims that you or any other party may have relating to that Add-on or your use of that Add-on.</p>
                <p>You acknowledge and agree that Time Tracker and its affiliates are third party beneficiaries of the End User License Agreement for each Add-on, and that, upon your acceptance of the terms and conditions of the license to any such Add-on, Time Tracker will have the right (and will be deemed to have accepted the right) to enforce such license against you as a third party beneficiary thereof.</p>
              </li>
              <li>
                <h3>Advertisements and Promotions</h3>
                <p>Time Tracker may run advertisements and promotions from third parties on the Services. Your business dealings or correspondence with, or participation in promotions of, advertisers other than Time Tracker, and any terms, conditions, warranties or representations associated with such dealings, are solely between you and such third party. Time Tracker is not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of third-party advertisers on the Services.</p>
              </li>
              <li>
                <h3>Warranty Disclaimer</h3>
                <p>The Site, the Content, the Add-ons and the Services are provided to you on an "as is" basis without warranties from Time Tracker of any kind, either express or implied. Time Tracker expressly disclaims all other warranties, express or implied, including without limitation implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. Time Tracker does not represent or warrant that Site, the Content, the Add-ons or the Services are accurate, complete, reliable, current or error-free.</p>
                <p>While Time Tracker attempts to make your access to and use of the Services safe, Time Tracker does not represent or warrant that the Site, the Content, the Add-ons or the Services are free of viruses or other harmful components.</p>
              </li>
              <li>
                <h3>Limitation of Liability; Indemnity</h3>
                <p>You waive and shall not assert any claims or allegations of any nature whatsoever against Time Tracker, its affiliates or subsidiaries, their sponsors, contractors, advertisers, vendors or other partners, any of their successors or assigns, or any of their respective officers, directors, agents or employees (collectively, the "Released Parties") arising out of or in any way relating to your use of the Site, the Content, the Add-ons or the Services, including, without limitation, any claims or allegations relating to infringement of proprietary rights, or allegations that any Released Party has or should indemnify, defend or hold harmless you or any third party from any claim or allegation arising from your use or other exploitation of the Site, the Content, the Add-ons or the Services. You use the Site, the Content, the Add-ons and the Services at your own risk.</p>
                <p>Without limitation of the foregoing, neither Time Tracker nor any other Released Party shall be liable for any direct, special, indirect or consequential damages, or any other damages of any kind, including but not limited to loss of use, loss of profits or loss of data, whether in an action in contract, tort (including but not limited to negligence) or otherwise, arising out of or in any way connected with the use of or inability to use the Site, the Content, the Add-ons or the Services, including without limitation any damages caused by or resulting from your reliance on the Site, the Content, the Add-ons or the Services or other information obtained from Time Tracker or any other Released Party or accessible via the Site, the Content, the Add-ons or the Services, or that result from mistakes, errors, omissions, interruptions, deletion of files or email, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from acts of god, communications failure, theft, destruction or unauthorized access to Time Tracker or any other Released Party's records, programs or services.</p>
                <p>In no event shall the aggregate liability of Time Tracker, whether in contract, warranty, tort (including negligence, whether active, passive or imputed), product liability, strict liability or other theory, arising out of or relating to the use of the Site, the Content, the Add-ons or the Services exceed any compensation paid by you for access to or use of the Site, the Content, the Add-ons or the Services, as the case may be, during the three months prior to the date of any claim.</p>
                <p>You shall defend, indemnify and hold harmless Time Tracker and the other Released Parties from any loss, damages, liabilities, costs, expenses, claims and proceedings arising out of your use of the Site, the Content, the Add-ons and the Services, and if you are a Subscriber, from your Members’ use of the Services and from the use of the Site, the Content, the Add-ons and the Services by any person to whom you give access to your account (including as staff), including any claims made by any person that any of your, and if you are a Subscriber, your Members’, User Content infringes the rights, including the intellectual property rights, of any third party.</p>
              </li>
              <li>
                <h3>Communications</h3>
                <p>Notices that we give you (other than notice of amendment of these Terms, which is discussed in the introduction of these Terms) may be provided in any number of ways, depending on the circumstances. For example, we may email you or telephone you at the contact information you provide in your Registration Data. Or we may post a notice to Subscribers and Members in the dashboard area of your account on the Site, or post the notice elsewhere on the Site. When we post notices on the Site, we post them in the area of the Site suitable to the notice. It is your responsibility to periodically review the Site for notices.</p>
                <p>Subject to the Privacy Policy, if you send to Time Tracker or post on the Site in any public area any information, ideas, inventions, concepts, techniques or know-how ("User Submissions"), for any purpose, including the developing, manufacturing and/or marketing or products or services incorporating such information, you acknowledge that Time Tracker can use the User Submissions without acknowledgement or compensation to you, and you waive any claim of ownership or compensation or other rights you may have in relation to the User Submissions. We actively review User Submissions for new ideas. If you wish to preserve any interest you might have in your User Submissions, you should not post them to the Site or send them to us.</p>
              </li>
              <li>
                <h3>Applicable Law and Venue</h3>
                <p>The Services are controlled by Time Tracker and operated by it from its offices in Fort Lee, New Jersey. You and Time Tracker both benefit from establishing a predictable legal environment in regard to the Services. Therefore, you and Time Tracker explicitly agree that all disputes, claims or other matters arising from or relating to your use of the Site, the Content, the Add-ons or the Services will be governed by the laws of the state of New Jersey and the federal laws of United States applicable therein. The United Nations Convention on Contracts for the International Sale of Goods does not apply to these Terms.</p>
                <p>Except where prohibited by applicable law, any claim, dispute or controversy (whether in contract or tort, pursuant to statute or regulation, or otherwise, and whether preexisting, present or future) involving Time Tracker and arising out of or relating to (a) these Terms; (b) the Site, the Content, the Add-ons or the Services; (c) oral or written statements, advertisements or promotions relating to these Terms or to the Site, the Content, the Add-ons or the Services; or (d) the relationships that result from these Terms or the Site, the Content, the Add-ons or the Services (collectively, a "Claim"), will be referred to and determined by a sole arbitrator (to the exclusion of the courts). Except where prohibited by applicable law, you waive any right you may have to commence or participate in any className action against Time Tracker related to any Claim and, where applicable, you also agree to opt out of any className proceedings against Time Tracker. If you have a Claim, you should give written notice to arbitrate at the address specified below. If we have a Claim, we will give you notice to arbitrate at your address provided in your Registration Data. Arbitration will be conducted by one arbitrator pursuant to the commercial arbitration laws and rules in effect on the date of the notice in the state of New Jersey.</p>
                <p>To the extent arbitration as described in the immediately preceding paragraph is prohibited by applicable law, you agree that all Claims will be heard and resolved in a court of competent subject matter jurisdiction located in Fort Lee, New Jersey. You consent to the personal jurisdiction of such courts over you, stipulate to the fairness and convenience of proceeding in such courts, and covenant not to assert any objection to proceeding in such courts.</p>
                <p>If you choose to access the Services from locations other than Fort Lee, New Jersey, you will be responsible for compliance with all local laws of such other jurisdiction and you agree to indemnify Time Tracker and the other Released Parties for your failure to comply with any such laws.</p>
              </li>
              <li>
                <h3>Termination/Modification of License and Site Offerings</h3>
                <p>Notwithstanding any provision of these Terms, Time Tracker reserves the right, without notice and in its sole discretion, without any notice or liability to you, to (a) terminate your license to use the Services, or any portion thereof (including any use of any Add-on) and the rights of any third party to which you have granted access to your User Content through the Time Tracker Mobile Application; (b) block or prevent your future access to and use of all or any portion of the Services or Content (including any use of any Add-on) and the rights of any third party to which you have granted access to your User Content through the Time Tracker Mobile Application; (c) change, suspend or discontinue any aspect of the Services or Content; and (d) impose limits on the Services or Content.</p>
              </li>
              <li>
                <h3>Inactive Accounts; Termination of Agreement</h3>
                <p>If your account is inactive for at least three months, we may deactivate your account. Deactivated accounts are not deleted – they are placed in storage and can be restored. We will notify you by email if we decide to deactivate your account. If you know in advance that your account will be inactive at some time and don’t want us to deactivate it, let us know in advance at support@MyTimeTracker.app. If after your account has been deactivated it stays inactive and we don’t hear from you, we may terminate it at any time and without notice.</p>
                <p>You and Time Tracker may terminate your use of the Services including your agreement to these Terms at any time. If you terminate your use of the Services you must pay the fees applicable for the balance of the then current billing period (if your billing period is monthly, we will prorate your account to the nearest month-end after termination) including for any Add-ons you may be using. When your Time Tracker account is terminated, your User Content will, shortly thereafter, not appear on the Services, except for User Content submitted to public areas of the Site such as the blog, forum, or product reviews, which may remain on the Site after termination. We may also retain an archival copy of your User Content after termination, and you hereby grant us a non-exclusive, perpetual, irrevocable license to maintain such archival copy for our internal business purposes.</p>
                <p>If these Terms expire or terminate for any reason, Sections 4, 8, 9, 11, 12, 14, 16, and 17, and any representation or warranty you make in these Terms, shall survive indefinitely.</p>
              </li>
              <li>
                <h3>Miscellaneous</h3>
                <p>If any provision of these Terms shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions. Time Tracker may assign any or all of its rights hereunder to any party without your consent. You are not permitted to assign any of your rights or obligations hereunder without the prior written consent of Time Tracker, and any such attempted assignment will be void and unenforceable. These Terms constitute the entire agreement between you and Time Tracker regarding your use of the Site, the Content, the Add-ons and the Services, and supersede all prior or contemporaneous communications whether electronic, oral or written between you and Time Tracker regarding your use of them. The parties confirm that it is their wish that these Terms, as well as any other documents relating to this Terms, including notices, have been and shall be drawn up in the English language only.</p>
              </li>
            </ol>
          </div>
        </section>

      </main>
      <Footer />

      <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

    </Screen>
  )
}

export default Terms