import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Screen from '../../Components/Screen';
import BreadCrumb from '../../Components/BreadCrumb';
import Table from '../../Components/Table';

// Hooks
import useLoadData from '../../Hooks/useLoadData';
import useUpdateData from '../../Hooks/useUpdateData';

const Employees = () => {
  const navigate = useNavigate();

  // redux
  const { token } = useSelector(state => state.auth);
  const { users, schedule } = useSelector(state => state.employees);

  // Hooks
  const { loadEmployees, loadEmployeeSchedule } = useLoadData();
  const { onDeleteEmployee } = useUpdateData();

  // States
  const [loading, setLoading] = useState();

  const companyID = token?.companyID;

  // Functions
  const fetchData = async () => {
    setLoading(true);
    await loadEmployees(companyID);
    await loadEmployeeSchedule(companyID);
    setLoading(false);
  };

  const onView = async (employee) => navigate('/employee_profile', { state: { employee } });

  useEffect(() => {
    fetchData();
  }, []);

  // Constants
  const labels = [
    '#',
    'Email Address',
    'Employee Name',
    // 'Phone Number',
    'Shift',
    'Hourly Rate',
    // 'Worked Hours',
    // 'Amount Earned',
    'Action',
  ];
  const data = users?.map((item) => {
    // Check if schedule is defined and not empty
    const hours = schedule && schedule.length > 0 ?
      ((schedule
        .filter(sc => sc.userID === item.userID)
        .map((sc) => ((sc.clockOutTime - sc.clockInTime) - (sc.breakTime?.[1] - sc.breakTime?.[0])))
        .reduce((a, b) => a + b, 0)) / 3600000).toFixed(2) :
      0;

    const amount = item.earningPerHour * hours;
    return {
      data: { ...item, hours, amount },
      fields: [
        item.email,
        item.name,
        item.shift?.toUpperCase(),
        // item.phone,
        item.earningPerHour,
        // hours,
        // amount,
      ]
    }
  });

  return (
    <Screen>
      <main className="page-content">
        <BreadCrumb flow={[
          { label: 'All Employees', to: '/employees' },
          { label: 'All Employees', to: '/employees' },
        ]} />
        <div className="card">
          <div className="card-body">
            <Table
              id={"companies"}
              labels={labels}
              data={data}
              onView={onView}
              onDelete={onDeleteEmployee}
            />
          </div>
        </div>
      </main>
      <a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
    </Screen>
  );
};

export default Employees;
