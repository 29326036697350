import { createSlice } from "@reduxjs/toolkit";

const employeeSlice = createSlice({
  name: 'employees',
  initialState: {
    users: [],
    schedule: []
  },
  reducers: {
    allEmployees: (state, action) => {
      state.users = action.payload
    },
    newEmployee: (state, action) => {
      state.users = [...state.users, action.payload]
    },
    deleteEmployee: (state, action) => {
      state.users = state.users?.filter(emp => emp.id != action.payload.id)
    },
    updateEmployee: (state, action) => {
      state.users = state.users?.map(emp => emp.id == action.payload.id ? action.payload : emp)
    },
    employeeSchedule: (state, action) => {
      state.schedule = action.payload
    }
  }
});

export const {allEmployees, newEmployee, deleteEmployee, updateEmployee, employeeSchedule} = employeeSlice.actions

export default employeeSlice.reducer