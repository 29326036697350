import React from 'react'
import { useNavigate } from 'react-router-dom';

const NavbarItem = ({ item }) => {
  const navigate = useNavigate();
  const isAdmin = item.label.includes('Admin');
  return (
    <li>
      <a
        href={item?.menu ? "javascript:;" : item?.to}
        className={item?.menu ? 'has-arrow' : null}
        onClick={item?.menu
          ? null :
          () => navigate(item?.to, { state: { isAdmin } })
        }
      >
        <div className="parent-icon">{item?.icon}</div>
        <div className="menu-title">{item?.label}</div>
      </a>
      <ul>
        {
          item?.menu?.map((menu, index) => menu == null ? null : (
            <li key={index}>
              <a href={menu.to}>{menu.icon}{menu.label}
              </a>
            </li>
          ))
        }
      </ul>
    </li>
  )
}

export default NavbarItem;