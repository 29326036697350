import React from 'react'

const Form = ({ fields }) => {
  const hhmm = (date) => {
    var time = new Date(date);
    if (isNaN(time)) return ''
    return (
      ("0" + time.getHours()).slice(-2) + ":" +
      ("0" + time.getMinutes()).slice(-2)
    )
  }
  return (
    <form action="">
      {
        fields?.map((item, index) => {
          return (
            <div key={index} className="card">
              <div className="card-body">
                {/* Title */}
                <h6 className="mb-0 text-uppercase">{item.title}</h6>
                <hr />

                <div className="row">
                  <div className="col-12 col-md-4 mb-3">
                    <label className="form-label">Work Start Time:</label>
                    <input value={hhmm(item.workTime[0])} onChange={(e) => item.setWorkStart(e.target.value)} type="time" className="form-control" />
                  </div>
                  <div className="col-12 col-md-2 mb-3">
                    <label className="form-label">Break Start Time:</label>
                    <input value={hhmm(item.breakTime[0])} onChange={(e) => item.setBreakStart(e.target.value)} type="time" className="form-control" />
                  </div>
                  <div className="col-12 col-md-2 mb-3">
                    <label className="form-label">Break End Time:</label>
                    <input value={hhmm(item.breakTime[1])} onChange={(e) => item.setBreakEnd(e.target.value)} type="time" className="form-control" />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label className="form-label">Work End Time:</label>
                    <input value={hhmm(item.workTime[1])} onChange={(e) => item.setWorkEnd(e.target.value)} type="time" className="form-control" />
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </form>
  )
}

export default Form