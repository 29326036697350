import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import useLoadData from '../../Hooks/useLoadData';
import useUpdateData from '../../Hooks/useUpdateData';

import BreadCrumb from '../../Components/BreadCrumb';
import Screen from '../../Components/Screen';
import Popup from '../../Components/Popup';
import Form from './Form';

import { handleShow } from '../../features/popup/slice';

const Schedule = () => {

  // Redux
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);
  const { schedule: schedules } = useSelector(state => state.companies);

  // Hooks
  const { loadCompanySchedule } = useLoadData();
  const { onUpdateCompanySchedule } = useUpdateData();

  // States
  const [loading, setLoading] = useState();
  const [shift, setShift] = useState('day');

  const schedule = schedules?.find((item) => item.companyID == token?.companyID)
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  const [data, setData] = useState({
    mondayBreakTime: [],
    mondayTime: [],

    tuesdayBreakTime: [],
    tuesdayTime: [],

    wednesdayBreakTime: [],
    wednesdayTime: [],

    thursdayBreakTime: [],
    thursdayTime: [],

    fridayBreakTime: [],
    fridayTime: [],

    saturdayBreakTime: [],
    saturdayTime: [],

    sundayBreakTime: [],
    sundayTime: [],

    mondayNightBreakTime: [],
    mondayNightTime: [],

    tuesdayNightBreakTime: [],
    tuesdayNightTime: [],

    wednesdayNightBreakTime: [],
    wednesdayNightTime: [],

    thursdayNightBreakTime: [],
    thursdayNightTime: [],

    fridayNightBreakTime: [],
    fridayNightTime: [],

    saturdayNightBreakTime: [],
    saturdayNightTime: [],

    sundayNightBreakTime: [],
    sundayNightTime: [],
  });

  // Functions
  const loadData = async () => {
    setLoading(true);
    await loadCompanySchedule();
    setLoading(false);
  }

  const setStart = (key, value) => {
    const date = new Date()
    date.setHours(parseInt(value.split(':')[0]))
    date.setMinutes(parseInt(value.split(':')[1]))
    const timestamp = date.getTime()
    const temp = { ...data };
    if (timestamp >= data[key][1] && shift == 'day')
      alert('Starting time can not be greater than ending time')
    else
      temp[key] = [timestamp, data[key][1]].filter(time => time);
    setData(temp)
  }
  const setEnd = (key, value) => {
    const date = new Date()
    date.setHours(parseInt(value.split(':')[0]))
    date.setMinutes(parseInt(value.split(':')[1]))
    const timestamp = date.getTime()
    const temp = { ...data };
    if (timestamp <= data[key][0] && shift == 'day')
      alert('Ending time can not be less than starting time')
    else
      temp[key] = [data[key][0], timestamp].filter(time => time);
    setData(temp)
  }

  const fields = days.map(day => {
    const workTime = `${day.toLowerCase()}${shift == 'day' ? '' : 'Night'}Time`;
    const breakTime = `${day.toLowerCase()}${shift == 'day' ? '' : 'Night'}BreakTime`;

    return ({
      title: day,
      workTime: data[`${workTime}`],
      breakTime: data[`${breakTime}`],
      setWorkStart: (value) => setStart(workTime, value),
      setWorkEnd: (value) => setEnd(workTime, value),
      setBreakStart: (value) => setStart(breakTime, value),
      setBreakEnd: (value) => setEnd(breakTime, value),
      isValid:
        (data[workTime]?.length == 0 && data[breakTime]?.length == 0) ||
        (data[workTime]?.length == 2 && data[breakTime]?.length == 2),
    })
  })

  const onSubmit = () => {
    // validate
    const errors = fields?.map((item) => !item.isValid ? `Kindly fill all times for ${item.title}` : null).filter(item => item)
    if (errors.length > 0) {
      return alert(errors.join('\n'))
    }
    const send = {};
    Object.keys(data).map((key) => data[key].length == 0 ? null : send[key] = data[key])
    onUpdateCompanySchedule({ ...send, companyID: token?.companyID })
      .then(() => dispatch(handleShow({ screen: 'schedule', title: 'Schedule Updated' })))
      .catch((error) => dispatch(handleShow({ screen: 'schedule', title: 'Schedule could not be updated', body: error.message })))
  }

  useEffect(() => {
    loadData();
  }, [])

  useEffect(() => {
    const timing = {};
    for (let i = 0; i < 2; i++) {
      const mid = i == 0 ? '' : 'Night'
      for (let i = 0; i < days.length; i++) {
        const day = days[i];
        const workTime = `${day.toLowerCase()}${mid}Time`;
        const breakTime = `${day.toLowerCase()}${mid}BreakTime`;
        timing[workTime] = (schedule && schedule[workTime]) ? schedule[workTime] : [];
        timing[breakTime] = (schedule && schedule[breakTime]) ? schedule[breakTime] : [];
      }
    }
    setData(timing)
  }, [schedules])

  const color1 = `btn${shift != 'day' ? '-outline' : ''}-primary`;
  const color2 = `btn${shift != 'night' ? '-outline' : ''}-primary`;

  return (
    <Screen>
      <main className="page-content">
        <BreadCrumb flow={[
          { label: 'Company Schedule', to: '/schedule' },
          { label: 'Company Schedule', to: '/schedule' }
        ]} />
        <div className="row">
          {/* Shift Switcher */}
          <div className="col-12 d-flex justify-content-between gap-2 mb-3">
            <button onClick={() => setShift('day')} className={`btn ${color1} w-100`}>Day Shift</button>
            <button onClick={() => setShift('night')} className={`btn ${color2} w-100`}>Night Shift</button>
          </div>
          {/* Fields */}
          <div className="col-12">
            <Form fields={fields} />
          </div>
          {/* Submit Button  */}
          <div className="text-start">
            <button onClick={onSubmit} type="button" className="btn btn-primary px-4">Save Changes</button>
          </div>
        </div>
      </main>
      <Popup screen={'schedule'} />
    </Screen>
  )
}

export default Schedule