import React, { useState } from 'react'
import {useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Form as BForm } from 'react-bootstrap';
import { storage } from '../../Api/firebase.config';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import Screen from '../../Components/Screen'
import BreadCrumb from '../../Components/BreadCrumb'
import ProfileCard from './ProfileCard';
import Form from './Form';
import useUpdateData from '../../Hooks/useUpdateData';

import { handleShow } from '../../features/popup/slice';
import Popup from '../../Components/Popup';

const CompanyProfile = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  
  // Redux 
  const {token} = useSelector(state => state.auth)
  const {companies} = useSelector(state => state.companies)
  const companyID = location.state?.company.companyID;
  const company = {...location.state?.company, ...companies?.find((item)=>item.companyID == companyID)}

  // States
  const [loading, setLoading] = useState();
  const [data, setData] = useState({
    email: company?.email ? company.email : '',
    name: company?.name ? company.name : '',
    admin: company?.admin ? company.admin : '',
    package: company?.package ? company.package : 'Select Package',
    address: company?.address ? company.address : '',
    city: company?.city ? company.city : '',
    country: company?.country ? company.country : '',
    pin: company?.pin ? company.pin : '',
    phone: company?.phone ? company.phone : '',
    about: company?.about ? company.about : ''
  });
  const [image, setImage] = useState();

  // Hooks
  const {onUpdateCompany} = useUpdateData();

  // Constants
  const companyFields = [
    {
      id: 'inputEmail',
      label: 'Email Address',
      placeholder: "Enter Email",
      value: data.email,
      setValue: (value) => setData({...data, email: value}),
      disabled: true
    },
    {
      id: 'inputCompanyName',
      label: 'Company Name',
      placeholder: "Enter Company Name",
      value: data.name,
      setValue: (value) => setData({...data, name: value})
    },
    {
      id: 'inputADminName',
      label: 'Admin Name',
      placeholder: "Enter Admin Name",
      value: data.admin,
      setValue: (value) => setData({...data, admin: value})
    },
    {
      id: 'inputPAckage',
      label: 'Subscription Package',
      options: [
        'Select Package',
        'Monthly',
        'Quarterly',
        'Yearly'
      ],
      value: data.package,
      setValue: (value) => setData({...data, package: value})
    },
  ]

  const contactFields = [
    {
      id: 'inputAddress',
      label: 'Address',
      placeholder: "Enter Address",
      value: data.address,
      setValue: (value) => setData({...data, address: value}),
      full: true
    },
    {
      id: 'inputCity',
      label: 'City',
      placeholder: "Enter City",
      value: data.city,
      setValue: (value) => setData({...data, city: value})
    },
    {
      id: 'inputCountry',
      label: 'Country',
      placeholder: "Enter Country",
      value: data.country,
      setValue: (value) => setData({...data, country: value})
    },
    {
      id: 'inputPin',
      label: 'Pin Code',
      placeholder: "Enter Pin Code",
      value: data.pin,
      setValue: (value) => setData({...data, pin: value})
    },
    {
      id: 'inputPhoneNumber',
      label: 'Phone Number',
      placeholder: "Enter Phone Number",
      value: data.phone,
      setValue: (value) => setData({...data, phone: value})
    },
    {
      id: 'inputAbout',
      label: 'About',
      placeholder: "Enter About",
      value: data.about,
      setValue: (value) => setData({...data, about: value}),
      full: true,
      long: true,
    },
  ]

  // Functions
  const onUpdate = async () => {
    setLoading(true);
    const isEmpty = companyFields?.filter((item)=>!item.value).length;
    if(isEmpty) return alert('Company Fields can not be empty')
    try{
      await onUpdateCompany({id: companyID, companyID, ...data});
      dispatch(handleShow({screen: 'company', title: 'Company data updated'}));
      setLoading(false);
    }catch(err){
      dispatch(handleShow({screen: 'company', title: 'Company data could not be Updated'}));
      setLoading(false);
      console.log(err);
    }
  }

  const onUpload = async () => {
    const originalname = company?.imageURL?.split('ProfileImages%2F')[1]?.split('?')[0];
    const location = originalname ? originalname : Date.now().toString();
    // upload image
    const storageRef = ref(storage, `/ProfileImages/${location}`);
    const uploadTask = uploadBytesResumable(storageRef, image);
    uploadTask.on('state_changed', 
      (snapshot) => {
        const percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (err)=>{console.log(err)},
      async ()=>{
        const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
        // update image
        await onUpdateCompany({id: companyID, companyID, imageURL});
        setImage('')
      }
    )
  }

  return (
    <Screen>
      <main className="page-content">
        <BreadCrumb flow={[
          {label: 'All Companies', to:'/companies'}, 
          {label: 'All Companies', to: '/companies'}, 
          {label: 'Company Profile', to: '/company_profile'}
        ]} />

        <div className="row mt-3">
          {/* Form */}
          <div className="col-12 col-lg-8">
            <div className="card shadow-sm border-0">
              <div className="card-body">
                  <h5 className="mb-0">Update Information</h5>
                  <hr/>
                  {/* Form 1 */}
                  <Form title={'Company Information'} fields={companyFields}/>
                  {/* From 2 */}
                  <Form title={'Contact Information'} fields={contactFields}/>
                  {/* Submit Button */}
                  <div className="text-start">
                    <button disabled={loading} onClick={onUpdate} type="button" className="btn btn-primary px-4">Save Changes</button>
                  </div>
              </div>
            </div>
          </div>
          {/* Profile Card */}
          <div className="col-12 col-lg-4">
            <ProfileCard company={company} onShow={()=>dispatch(handleShow({screen: 'profile', title: 'Upload Image'}))} />
          </div>
        </div>
      </main>
      <Popup screen={'company'} />
      <Popup screen={'profile'} onSubmit = {onUpload} body={<>
        <BForm style={{marginBottom: 20}}>
          <BForm.Control 
            placeholder={'Upload Image'}
            type={'file'}
            value={image ? image.originalname : ''} 
            onChange={(e)=> setImage(e.target.files[0])}
          />
        </BForm>
      </>}/>
    </Screen>
  )
}

export default CompanyProfile