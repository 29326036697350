import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: 'chat',
  initialState:{
    chats: [],
    messages: []
  },
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload
    },
    setChats: (state, action) => {
      state.chats = action.payload
    },
    newMessage: (state, action) => {
      state.messages = [...state.messages, action.payload]
    }
  }
})

export const {setMessages, setChats, newMessage} = chatSlice.actions;
export default chatSlice.reducer