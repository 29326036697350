import { useNavigate } from 'react-router-dom'
import React from 'react'

const Table = ({ id, labels, data, onView, onDelete }) => {
  const navigate = useNavigate();
  const center = { display: 'flex', alignItems: 'center', justifyContent: 'center' }
  return (
    <div className="table-responsive">
      <table id={id} className="table table-striped table-bordered" style={{ width: '100%' }}>
        {/* Head */}
        <thead>
          <tr>
            {labels?.map((item, index) => <th key={index} style={item == 'Action' ? center : null}>{item}</th>)}
          </tr>
        </thead>
        {/* Body */}
        <tbody>
          {
            data?.map((item, index) => {
              return (
                <tr>
                  <th>{index + 1}</th>
                  {
                    item?.fields.map((row, rdx) => {
                      return (
                        row == undefined ? null :
                          row?.status == undefined ? <td>{row}</td>
                            :
                            <td>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  id="flexSwitchCheckChecked"
                                  type="checkbox"
                                  checked={row?.status}
                                  onChange={row?.onChange}
                                />
                                <label className="form-check-label" for="flexSwitchCheckChecked">{row?.status ? 'Active' : 'UnActive'}</label>
                              </div>
                            </td>
                      )
                    })
                  }
                  <td style={center}>
                    <div className="table-actions d-flex align-items-center gap-3 fs-6">
                      <a
                        title="View Company Profile"
                        className="text-primary"
                        onClick={() => onView(item?.data)}
                        style={{ cursor: 'pointer' }}
                      >
                        <i className="bi bi-eye-fill"></i>
                      </a>
                      <a
                        title="Delete"
                        className="text-danger"
                        onClick={() => onDelete(item?.data)}
                        style={{ cursor: 'pointer' }}
                      >
                        <i className="bi bi-trash-fill"></i>
                      </a>
                      <div
                        className="text-success"
                        onClick={() => navigate('/conversation', { state: { chat: item?.data } })}
                        style={{ cursor: 'pointer' }}
                      >
                        <i class="bi bi-chat" ></i>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export default Table