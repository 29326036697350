import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import Screen from '../../Components/Screen'
import BreadCrumb from '../../Components/BreadCrumb'
import Table from '../../Components/Table';

// Apis
import useLoadData from '../../Hooks/useLoadData';
import useUpdateData from '../../Hooks/useUpdateData';

const Companies = () => {
  const navigate = useNavigate()
  // Redux
  const { companies, schedule } = useSelector(state => state.companies)
  const { users } = useSelector(state => state.employees)

  // Hooks
  const {
    loadCompanies,
    loadCompanySchedule,
  } = useLoadData()
  const {
    onChangeStatus,
    onDeleteCompany
  } = useUpdateData()

  // States
  const [loading, setLoading] = useState();

  // Constants
  const labels = [
    '#',
    'Email Address',
    'Company Name',
    'Administrator Name',
    'N.o of Employees',
    'Working Days',
    'Status',
    'Action',
  ]
  const data = companies?.map((item) => {
    const employees = users?.filter((user) => user.companyID == item.companyID).length;
    const companySchedule = schedule?.find(sc => sc.companyID == item.companyID)
    const days = companySchedule ? (Object.keys(companySchedule)?.length - 2) / 2 : 0;
    return {
      data: { ...item, employees, days },
      fields: [
        item.email,
        item.name,
        item.admin,
        employees,
        `${days} Days`,
        item?.status == undefined ? '' :
          {
            status: item?.status,
            onChange: () => onChangeStatus(item, !item?.status)
          },
      ]
    }
  })

  // Functions
  const onView = async (company) => navigate('/company_profile', { state: { company } })
  const loadData = async () => {
    setLoading(true);
    await loadCompanies();
    await loadCompanySchedule();
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <Screen>
      <main className="page-content">
        <BreadCrumb flow={[
          { label: 'All Companies', to: '/companies' },
          { label: 'All Companies', to: '/companies' },
        ]} />
        <div className="card">
          <div className="card-body">
            <Table
              id={"companies"}
              labels={labels}
              data={data}
              onView={onView}
              onDelete={onDeleteCompany}
            />
          </div>
        </div>
      </main>
      <a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
    </Screen>
  )
}

export default Companies