import { useSelector, useDispatch } from 'react-redux';
import { firestore as db } from "../Api/firebase.config";

// Redux
import { allEmployees, employeeSchedule } from '../features/employees/slice';
import { allCompanies, companySchedule } from '../features/companies/slice';
import { setMessages, setChats } from '../features/chat/slice';

export default () => {
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);

  const loadCompanies = async () => {
    try {
      const response = await (await db.collection('Company')).get();
      const companies = response.docs.map((item) => ({ ...item.data(), id: item.id }));
      dispatch(allCompanies(companies))
    } catch (err) {
      console.log(err);
    }
  }

  const loadEmployees = async (companyID) => {
    try {
      var response = null;

      if (companyID) response = await (await db.collection('Users').where('companyID', '==', companyID)).get();
      else response = await (await db.collection('Users')).get();

      const employees = response.docs.map((item) => ({ ...item.data(), id: item.id }));
      dispatch(allEmployees(employees))
    } catch (err) {
      console.log(err);
    }
  }

  const loadCompanySchedule = async () => {
    try {
      var query = db.collection('CompanySchedule');
      if (token?.companyID) query = query.where('companyID', '==', token?.companyID);

      const response = (await query.get());
      const schedule = response.docs.map((item) => ({ ...item.data(), id: item.id }));
      dispatch(companySchedule(schedule))
    } catch (err) {
      console.log(err);
    }
  }

  const loadEmployeeSchedule = async (companyID) => {
    try {
      var response = null;

      if (companyID) response = await (await db.collection('EmployeeSchedule').where('companyID', '==', companyID)).get();
      else response = await (await db.collection('EmployeeSchedule')).get();

      const schedule = response.docs.map((item) => ({ ...item.data(), id: item.id }));
      dispatch(employeeSchedule(schedule))
    } catch (err) {
      console.log(err);
    }
  }

  const loadChats = async (role, companyID) => {
    try {
      db.collection(`${role}Chat`).onSnapshot((snapshot) => {
        const messages = snapshot.docs
          .map((item) => ({ ...item.data(), id: item.id }))
          .sort((a, b) => a.timeStamp - b.timeStamp)

        if (role == "Admin") return dispatch(setMessages(messages))
        // If role is company, Then fetch all the admins as well
        db.collection('AdminChat').onSnapshot((snapshot) => {
          const data = snapshot.docs.map((item) => ({ ...item.data(), id: item.id }))
          dispatch(setMessages([...messages, ...data]))
        })
      })
      db.collection(role == 'Admin' ? 'Company' : 'Users').onSnapshot((snapshot) => {
        const chats = snapshot.docs
          .map((item) => ({ ...item.data(), id: item.id }))
          .filter((item) => companyID ? item.companyID == companyID : true)

        if (role == "Admin") return dispatch(setChats(chats))
        // If role is company, Then fetch all the admins as well
        db.collection('Admin').onSnapshot((snapshot) => {
          const data = snapshot.docs.map((item) => ({ ...item.data(), id: item.id, isAdmin: true }))
          dispatch(setChats([...chats, ...data]))
        })
      })
    } catch (err) {
      console.log(err);
    }
  }

  return {
    loadCompanies,
    loadEmployees,
    loadCompanySchedule,
    loadEmployeeSchedule,
    loadChats
  }
}