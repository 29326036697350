import { createSlice } from "@reduxjs/toolkit";

const popupSlice = createSlice({
  name: 'popup',
  initialState: {
    screen: '',
    title: '',
    body: ''
  },
  reducers: {
    handleClose: (state) => {
      state = {};
      return state
    },
    handleShow: (state, action) => {
      state.screen = action.payload.screen;
      state.title = action.payload.title;
      state.body = action.payload.body;
    }
  }
})

export const {handleClose,handleShow} = popupSlice.actions;

export default popupSlice.reducer;