import React from 'react'

const PageHeading = ({title}) => {
  return (
    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div className="breadcrumb-title pe-3" style={{border: 'none'}}>{title}</div>
    </div>
  )
}

export default PageHeading