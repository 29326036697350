import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Form as BForm } from 'react-bootstrap';
import { storage } from '../../Api/firebase.config';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import useUpdateData from '../../Hooks/useUpdateData';

import Screen from '../../Components/Screen'
import BreadCrumb from '../../Components/BreadCrumb'
import Form from '../NewEmployee/Form';
import ProfileCard from './ProfileCard';

import { handleShow } from '../../features/popup/slice';
import Popup from '../../Components/Popup';

const EmployeeProfile = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // Redux 
  const { token } = useSelector(state => state.auth)
  const { users } = useSelector(state => state.employees)
  const userID = location.state?.employee.userID;
  const employee = { ...location.state?.employee, ...users?.find((item) => item.userID == userID) }

  // Hooks
  const { onUpdateEmployee } = useUpdateData();

  // States
  const [loading, setLoading] = useState();
  const [image, setImage] = useState();
  const [data, setData] = useState({
    email: employee?.email ? employee.email : '',
    name: employee?.name ? employee.name : '',
    earningPerHour: employee?.earningPerHour != undefined ? employee.earningPerHour : '',
    shift: employee?.shift != undefined ? employee.shift : 'day'
  });

  const fields = [
    {
      id: 'inputEmail',
      label: 'Enter Email',
      placeholder: "Enter Email",
      value: data.email,
      setValue: (value) => setData({ ...data, email: value }),
      disabled: true,
    },
    {
      id: 'inputCompany',
      label: 'Enter Company',
      placeholder: "Enter Company",
      value: token?.name,
      setValue: (value) => setData({ ...data, company: value }),
      disabled: true,
    },
    {
      id: 'inputName',
      label: 'Enter Full Name',
      placeholder: "Enter Full Name",
      value: data.name,
      setValue: (value) => setData({ ...data, name: value })
    },
    {
      id: 'inputHourlyRate',
      label: 'Enter Hourly Rate',
      placeholder: "Enter Hourly Rate",
      value: data.earningPerHour,
      setValue: (value) => setData({ ...data, earningPerHour: value }),
      type: 'number'
    },
    {
      id: 'inputShift',
      label: 'Shift',
      placeholder: "Select Working Shift",
      value: data.shift,
      setValue: (value) => setData({ ...data, shift: value })
    },
  ]

  // Functions
  const onUpdate = async () => {
    setLoading(true);
    const isEmpty = fields?.filter((item) => item.value == '' || item.value == undefined).length;
    if (isEmpty) return alert('Employee Fields can not be empty')
    try {
      await onUpdateEmployee({ id: userID, userID, ...data });
      dispatch(handleShow({ screen: 'employee', title: 'Employee data updated' }))
      setLoading(false);
    } catch (err) {
      dispatch(handleShow({ screen: 'employee', title: 'Employee data could not be Updated', body: err.message }))
      setLoading(false);
      console.log(err);
    }
  }

  const onUpload = async () => {
    const originalname = employee?.imageURL?.split('ProfileImages%2F')[1]?.split('?')[0];
    const location = originalname ? originalname : Date.now().toString();
    // upload image
    const storageRef = ref(storage, `/ProfileImages/${location}`);
    const uploadTask = uploadBytesResumable(storageRef, image);
    uploadTask.on('state_changed',
      (snapshot) => {
        const percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (err) => { console.log(err) },
      async () => {
        const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
        // update image
        await onUpdateEmployee({ id: userID, userID, imageURL });
        setImage('')
      }
    )
  }

  return (
    <Screen>
      <main className="page-content">
        <BreadCrumb flow={[
          { label: 'All Employees', to: '/employees' },
          { label: 'All Employees', to: '/employees' },
          { label: 'Employee Profile', to: '/employee_profile' }
        ]} />

        <div className="row mt-3">
          {/* Left Form */}
          <div className="col-12 col-lg-8">
            <Form title={'Update Information'} fields={fields} />
            {/* Submit Button */}
            <div className="text-start">
              <button disabled={loading} onClick={onUpdate} type="button" className="btn btn-primary px-4">Save Changes</button>
            </div>
          </div>
          {/* Right Card */}
          {/* <div className="col-12 col-lg-4">
            <ProfileCard
              employee={{ ...employee, company: token?.name }}
              onShow={() => dispatch(handleShow({ screen: 'profile', title: 'Upload Image' }))}
            />
          </div> */}
        </div>
      </main>
      <a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
      <Popup screen={'employee'} />
      <Popup screen={'profile'} onSubmit={onUpload} body={<>
        <BForm style={{ marginBottom: 20 }}>
          <BForm.Control
            placeholder={'Upload Image'}
            type={'file'}
            value={image ? image.originalname : ''}
            onChange={(e) => setImage(e.target.files[0])}
          />
        </BForm>
      </>} />
    </Screen>
  )
}

export default EmployeeProfile