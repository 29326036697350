import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

const BreadCrumb = ({ flow }) => {
  const navigate = useNavigate();
  return (
    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div className="breadcrumb-title pe-3">{flow[0]?.label}</div>
      <div className="ps-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 p-0">
            <li className="breadcrumb-item"><Link to={flow[0]?.to} ><i className="bx bx-home-alt"></i></Link>
            </li>
            {
              flow?.map((item, index) => {
                if (index == 0) return;
                const active = index == flow?.length - 1;
                const previous = flow[index - 1]
                return (
                  <li
                    key={index}
                    className={`breadcrumb-item${active ? ' active' : ''}`}
                    aria-current={active ? "page" : null}
                  >
                    {
                      active ? item.label : <Link to={previous?.to} >{previous?.label}<i ></i></Link>
                    }
                  </li>
                )
              })
            }
          </ol>
        </nav>
      </div>
    </div>
  )
}

export default BreadCrumb