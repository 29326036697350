import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

// Components
import Screen from '../../Components/Screen'
import Item from './Item'
import PageHeading from '../../Components/PageHeading';

// Hooks
import useLoadData from '../../Hooks/useLoadData';

const Dashboard = () => {

  // Redux State
  const { token } = useSelector(state => state.auth);
  const { users, schedule } = useSelector(state => state.employees);
  const { companies } = useSelector(state => state.companies);

  // Hooks
  const {
    loadCompanies,
    loadEmployees,
    loadEmployeeSchedule
  } = useLoadData();

  // States
  const [loading, setLoading] = useState();

  // Constants
  const companyID = token?.companyID

  // Functions
  const fetchData = async () => {
    setLoading(true);
    if (token?.occupation == 'Admin') await loadCompanies();
    await loadEmployees(companyID);
    await loadEmployeeSchedule(companyID);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [])


  const totalCompanies = companies?.length;
  const weeklyCompanies = companies?.length / 4;

  const totalUsers = users?.length;
  const weeklyUsers = users?.length / 4;

  const totalHours = ((schedule
    ?.map((sc) => ((sc.clockOutTime ? (sc.clockOutTime - sc.clockInTime) : 0) - (sc.breakTime ? (sc.breakTime[1] - sc.breakTime[0]) : 0)))
    .reduce((a, b) => a + b, 0)) / 3600000)
  const weeklyHours = totalHours / 4;

  const totalBillings = users
    ?.map((user) => {
      const rate = user.earningPerHour;
      const hours = schedule
        .filter(item => item.userID == user.userID)
        .map((item) => ((item.clockOutTime ? (item.clockOutTime - item.clockInTime) : 0) - (item.breakTime ? (item.breakTime[1] - item.breakTime[0]) : 0)))
        .reduce((a, b) => a + b, 0);
      return rate * new Date(hours).getHours()
    })
    .reduce((a, b) => a + b, 0);
  const weeklyBillings = totalBillings / 4;

  return (
    <Screen>
      <main className="page-content">
        <PageHeading title={'Dashboard'} />
        <div className="row row-cols-1 row-cols-lg-2">
          {
            token?.occupation != 'Admin' ? null :
              <div className="col">
                <Item
                  title={'Total Registered Companies'}
                  value={totalCompanies}
                  subtitle={`${(weeklyCompanies ? weeklyCompanies : 0).toFixed(2)} companies registered this week`}
                  icon={<i className="bx bx-building"></i>}
                  bg={'bg-primary'}
                />
              </div>
          }
          <div className="col">
            <Item
              title={'Total Registered Users'}
              value={totalUsers}
              subtitle={`${(weeklyUsers ? weeklyUsers : 0).toFixed(2)} users registered this week`}
              icon={<i className="bi bi-person"></i>}
              bg={'bg-success'}
            />
          </div>
          <div className="col">
            <Item
              title={'Total Clocked Hours'}
              value={totalHours}
              subtitle={`${(weeklyHours ? weeklyHours : 0).toFixed(2)} hours clocked this week`}
              icon={<i className="bx bx-time"></i>}
              bg={'bg-orange'}
            />
          </div>
          <div className="col">
            <Item
              title={'Total Billing'}
              value={totalBillings}
              subtitle={`${(weeklyBillings ? weeklyBillings : 0).toFixed(2)} billed this week`}
              icon={<i className="bi bi-currency-dollar"></i>}
              bg={'bg-info'}
            />
          </div>
        </div>
      </main>
    </Screen>
  )
}

export default Dashboard