import React from 'react'

const Form = ({ title, fields }) => {
  return (
    <div className="card">
      <div className="card-body">
        {/* Title */}
        <h6 className="mb-0 text-uppercase">{title}</h6>
        <hr />
        <div className="row">
          {
            fields?.map((item, index) => {
              return (
                <div key={index} className="col-12 col-md-6 mb-3">
                  <label className="form-label">{item.label}:</label>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {
                      item.label == 'Shift'
                        ?
                        <select class="form-select" aria-label="Default select example" onChange={(e) => item.setValue(e.target.value)}>
                          <option selected={item.value == 'day'} value="day">Day</option>
                          <option selected={item.value == 'night'} value="night">Night</option>
                        </select>
                        :
                        <input
                          id={item.id}
                          value={item.value}
                          placeholder={item.placeholder}
                          onChange={(e) => item.setValue(e.target.value)}
                          type={item.type ? item.type : "text"}
                          className="form-control"
                          disabled={item.disabled}
                        />
                    }
                    {
                      item.label != 'Password' ? null :
                        <button
                          onClick={item.setValue}
                          type="button"
                          class="btn btn-outline-primary btn-sm btn-block"
                          style={{ width: '200px', marginLeft: '20px' }}
                        >
                          Generate Password
                        </button>
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Form