import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container footer-bottom clearfix">
        <div className="copyright">
          &copy; Copyright <strong><span>TimeTracker</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
          <Link style={{ color: '#ffffff', marginRight: 20 }} to="/privacy">Privacy Policy</Link>
          <Link style={{ color: '#ffffff' }} to="/terms">Terms & Conditions</Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer