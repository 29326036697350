import React, { useState } from 'react'
import Form from './Form'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { login } from '../../features/auth/slice';
import { onSignup } from '../../Api/auth';
import Popup from '../../Components/Popup';
import { handleShow } from '../../features/popup/slice';

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: '',
    name: '',
    admin: '',
    package: '',
    password: '',
    confirmPassword: ''
  });
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const fields = [
    {
      label: 'Email',
      placeholder: 'Enter Email',
      icon: <i className="bi bi-envelope-fill"></i>,
      value: credentials.email,
      setValue: (e) => setCredentials({ ...credentials, email: e.target.value }),
      id: "inputEmailAddress",
      type: 'email'
    },
    {
      label: 'Company Name',
      placeholder: 'Enter Company Name',
      icon: <i className="bi bi-person-circle"></i>,
      value: credentials.name,
      setValue: (e) => setCredentials({ ...credentials, name: e.target.value }),
      id: "inputCompanyName",
    },
    {
      label: 'Admin Name',
      placeholder: 'Enter Admin Name',
      icon: <i className="bi bi-person-circle"></i>,
      value: credentials.admin,
      setValue: (e) => setCredentials({ ...credentials, admin: e.target.value }),
      id: "inputAdminName",
    },
    {
      label: 'Subscription Package',
      placeholder: 'Enter Subscription Package',
      icon: <i className="bx bx-package"></i>,
      value: credentials.package,
      options: [
        'Select Package',
        'Monthly',
        'Quarterly',
        'Yearly'
      ],
      setValue: (e) => setCredentials({ ...credentials, package: e.target.value }),
      id: "inputPackage",
      type: 'select'
    },
    {
      label: 'Password',
      placeholder: 'Enter Password',
      icon: <i className="bi bi-lock-fill"></i>,
      value: credentials.password,
      setValue: (e) => setCredentials({ ...credentials, password: e.target.value }),
      id: "inputPassword",
      type: 'password'
    },
    {
      label: 'Confirm Password',
      placeholder: 'Enter Confirm Password',
      icon: <i className="bi bi-lock-fill"></i>,
      value: credentials.confirmPassword,
      setValue: (e) => setCredentials({ ...credentials, confirmPassword: e.target.value }),
      id: "inputConfirmPassword",
      type: 'password'
    },
  ]

  const onSubmit = async (e) => {
    e.preventDefault();
    const filled = fields.filter(item => !item.value);
    if (filled?.length != 0)
      return dispatch(handleShow({
        screen: 'signup',
        title: 'Unable to Create Account',
        body: 'Kindly fill all the fields'
      }));
    if (credentials.password != credentials.confirmPassword)
      return dispatch(handleShow({
        screen: 'signup',
        title: 'Unable to Create Account',
        body: 'Confirm Password is not same as Password'
      }));
    try {
      setLoading(true);
      const token = await onSignup(credentials);
      dispatch(login(token));
      navigate('/dashboard');
      // window.location.reload(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      dispatch(handleShow({
        screen: 'signup',
        title: 'Unable to Create Account',
        body: err.message
      }));
    }
  }

  return (
    <main className="authentication-content mt-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-4 mx-auto">
            <div className="card shadow rounded-5 overflow-hidden">
              <div className="card-body p-4 p-sm-5">
                <h5 className="card-title">Sign Up</h5>
                <p className="card-text">Register your company and track your working force!</p>
                <div className="login-separater text-center mb-2">
                  <hr />
                </div>
                <form onSubmit={onSubmit} className="form-body">
                  <div className="row g-3">
                    <Form fields={fields} />
                    <div className="col-12">
                      <div className="form-check form-switch">
                        <input onChange={() => setTerms(!terms)} className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={terms} />
                        <label className="form-check-label" for="flexSwitchCheckChecked">I Agree to the Trems & Conditions</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-grid">

                        <button type="submit" disabled={!terms || loading} className="btn btn-primary radius-30">{
                          loading ? 'Registering...' : 'Sign Up'
                        }</button>
                      </div>
                    </div>
                    <div className="col-12">
                      <p className="mb-0">Already have an account? <Link href="" to='/login'>Sign in here</Link></p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup screen={'signup'} />
    </main>
  )
}

export default Login