import React from 'react'

const Bubble = ({ item, isUs, selectedChat }) => {
  const timeArray = new Date(item.timeStamp).toLocaleTimeString().split(':');
  const ampm = timeArray[2].split(' ')[1];
  const time = `${timeArray[0]}:${timeArray[1]} ${ampm ? ampm : ''}`;
  return (
    <div className={`chat-content-${isUs ? 'rightside' : 'leftside'}`}>
      <div className={`d-flex ${isUs ? null : 'ms-auto'}`}>
        {
          isUs ? null : <img src={selectedChat?.imageURL} width="48" height="48" className="rounded-circle" alt="" />
        }
        <div className={`flex-grow-1 ${isUs ? 'm2-2' : 'ms-2'}`}>
          <p className={`mb-0 chat-time ${isUs ? 'text-end' : ''}`}>{isUs ? 'you' : selectedChat?.name}, {time}</p>
          <p className={`chat-${isUs ? 'right' : 'left'}-msg`}>{item.message}</p>
        </div>
      </div>
    </div>
  )
}
export default Bubble