import React from 'react'

const Item = ({ title, value, subtitle, icon, bg }) => {
  return (
    <div className="card rounded-4">
      <div className="card-body">
        <div className="d-flex align-items-center">
          <div className="">
            <p className="mb-1">{title}</p>
            <h4 className="mb-0">{(value ? value : 0)?.toFixed(2)}</h4>
            <p className="mb-0 mt-2 font-13"><i className="bi bi-arrow-up"></i><span>{subtitle}</span></p>
          </div>
          <div className={`ms-auto widget-icon ${bg} text-white`}>
            {icon}
          </div>
        </div>

      </div>
    </div>
  )
}

export default Item