import { createSlice } from "@reduxjs/toolkit";

const companySlice = createSlice({
  name: 'companies',
  initialState: {
    companies: [],
    schedule: []
  },
  reducers: {
    allCompanies: (state, action) => {
      state.companies = action.payload
    },
    deleteCompanies: (state, action) => {
      state.companies = state.companies?.filter(emp => emp.id != action.payload.id)
    },
    updateCompanies: (state, action) => {
      state.companies = state.companies?.map(emp => emp.id == action.payload.id ? action.payload : emp)
    },
    companySchedule: (state, action) => {
      state.schedule = action.payload
    },
    updateSchedule: (state, action) => {
      if(!state.schedule.find(item=>item.companyID == action.payload.companyID)){
        state.schedule = [...state.schedule, action.payload]
        return;
      }
      state.schedule = state.schedule?.map(emp => emp.companyID == action.payload.companyID ? action.payload : emp)
    }
  }
});

export const {allCompanies, deleteCompanies, updateCompanies, companySchedule, updateSchedule} = companySlice.actions

export default companySlice.reducer