import "./assets/css/style.css";

import React, { useState } from "react";
import { useDispatch } from "react-redux";

import useSidebar from "../../Hooks/useSidebar";
import { handleShow } from "../../features/popup/slice";

import Footer from "./Footer";
import Hero from "./Hero";
import Screen from "./Screen";
import Popup from "../../Components/Popup";

const Home = () => {
  const yt = "https://youtube.com/shorts/KvLGpOzZxws?feature=share";
  const yt_ID = "KvLGpOzZxws";
  const dispatch = useDispatch();

  return (
    <Screen>
      <Hero />

      <main id="main" style={{ background: "white" }}>
        <section id="about" className="about">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-6 col-lg-6 video-box d-flex justify-content-center align-items-stretch"
                data-aos="zoom-in"
              >
                <div
                  onClick={() =>
                    dispatch(
                      handleShow({
                        screen: "home",
                      })
                    )
                  }
                  className="venobox play-btn mb-4"
                />
              </div>

              <div className="col-xl-6 col-lg-6 d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                <div className="box-heading" data-aos="fade-up">
                  <h4>About us</h4>
                  {/* <h3>What's TimeTracker?</h3> */}
                  <h3>Introducing Time Tracker,</h3>

                  <p>
                    Time Tracker runs in the cloud, so there's no need to worry
                    about maintaining software or servers. This means you can
                    focus on managing your business, while we take care of the
                    backend for you.
                  </p>
                  <p>
                    With Time Tracker, managers have complete visibility of
                    their workforce's attendance status. You can see who's in
                    and who's out at any time and from anywhere, using any
                    device with internet access. Additionally, you can run time
                    and attendance reports to monitor employee performance,
                    generate payroll, and identify trends in attendance
                    patterns.
                  </p>
                  <p>
                    Say goodbye to manual time tracking and switch to Time
                    Tracker to streamline your workforce management. Try Time
                    Tracker today and see the difference it can make to your
                    business!
                  </p>
                  {/* <p>
            The TimeTracker mobile App works with smartphones and
            tablets running Google Android or Apple iOS including
            iPhone, iPad and iPod Touch.
          </p>
          <p>
            TimeTracker is the perfect solution for small and medium
            businesses looking to track time and attendance without the
            usual costs and overhead of traditional systems.
          </p> */}
                </div>

                <div data-aos="fade-up">
                  <h4 className="title">
                    The versatility of Time Tracker allows for a wide variety of
                    applications:
                  </h4>
                  <ul>
                    <li className="description">
                      Track employee time and attendance for your business
                    </li>
                    {/* <li className="description">
              Track student attendance for your school
            </li> */}
                    <li className="description">
                      Track time spent across multiple projects or clients
                    </li>
                    <li className="description">
                      Track member attendance for an event
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container">
            <div className="section-title" data-aos="zoom-in">
              <h2>Benefits</h2>
              <h3>
                Our Awesome <span>Benefits</span>
              </h3>
              <p>
                Ut possimus qui ut temporibus culpa velit eveniet modi omnis est
                adipisci expedita at voluptas atque vitae autem.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div
                  className="icon-box d-flex flex-column w-100 rounded-0 h-75"
                  data-aos="zoom-in"
                >
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4>
                    <a href="">Get up and running in minutes</a>
                  </h4>
                  <p>
                    Our easy signup process combined with the ability to print
                    your own employee cards, means that you can be up and
                    running in as little as 5 Minutes!
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div
                  className="icon-box d-flex flex-column w-100 rounded-0 h-75"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <i className="bx bx-file"></i>
                  </div>
                  <h4>
                    <a href="">Checkin & Checkout Technology</a>
                  </h4>
                  <p>
                    With our Checkin & Checkout technology, Employees can Punch
                    In & Out in less than a second. Employees also have the
                    option to Punch by PIN if their card is not available.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                <div
                  className="icon-box d-flex flex-column w-100 rounded-0 h-75"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <i className="bx bx-tachometer"></i>
                  </div>
                  <h4>
                    <a href="">Cloud-Based</a>
                  </h4>
                  <p>
                    Because TimeTracker is cloud-based, there's no software or
                    hardware to maintain. Managers can access their time and
                    attendance data anywhere they have access to the internet.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div
                  className="icon-box d-flex flex-column w-100 rounded-0 h-75"
                  data-aos="zoom-in"
                >
                  <div className="icon">
                    <i class="bi bi-graph-up"></i>
                  </div>
                  <h4>
                    <a href="">Advanced Reporting</a>
                  </h4>
                  <p>
                    Managers have access to multiple online reports to track
                    attendance and calculate pay. Reports can be exported to
                    different formats allowing for easy integration with Payroll
                    systems.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div
                  className="icon-box d-flex flex-column w-100 rounded-0 h-75"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <i class="bi bi-person"></i>
                  </div>
                  <h4>
                    <a href=""> Employee Self-Service Portal</a>
                  </h4>
                  <p>
                    Using the employee self-service portal, employees can review
                    their activity, Punch In & Out, run reports and create or
                    edit time entries. Managers have full control over which
                    functionality each employee has access to.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                <div
                  className="icon-box d-flex flex-column w-100 rounded-0 h-75"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    {/* <i class="bi bi-map"></i> */}
                    <i class="bi bi-geo-alt-fill"></i>
                  </div>
                  <h4>
                    <a href="">GPS Location Tagging</a>
                  </h4>
                  <p>
                    Map the geographical location where employees punch in and
                    out from
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="why-us" className="why-us">
          <div className="container">
            <div className="section-title" data-aos="zoom-in">
              <h2>How it Works?</h2>
              <h3>
                Get started in <span>3 easy steps</span>!
              </h3>
              <p>
                Ut possimus qui ut temporibus culpa velit eveniet modi omnis est
                adipisci expedita at voluptas atque vitae autem.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <div className="box" data-aos="fade-up">
                  <span>01</span>
                  <h4>Signup</h4>
                  <p>Signup and add your employees.</p>
                </div>
              </div>

              <div className="col-lg-4 mt-4 mt-lg-0">
                <div className="box" data-aos="fade-up" data-aos-delay="100">
                  <span>02</span>
                  <h4>Logins</h4>
                  <p>Allow your Employees to login</p>
                </div>
              </div>

              <div
                className="col-lg-4 mt-4 mt-lg-0"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="box">
                  <span>03</span>
                  <h4>Download</h4>
                  <p>Download TimeTracker App to track</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="pricing" className="pricing">
          <div className="container">
            <div className="section-title" data-aos="zoom-in">
              <h2>Pricing</h2>
              <h3>
                Check our <span>Pricing</span>
              </h3>
              <p>
                Ut possimus qui ut temporibus culpa velit eveniet modi omnis est
                adipisci expedita at voluptas atque vitae autem.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="box" data-aos="zoom-in" data-aos-delay="200">
                  <h3>Free</h3>
                  <h4>
                    <sup>$</sup>0<span> / month</span>
                  </h4>
                  <ul>
                    <li>Employees up to 10</li>
                    <li>Administrator up to 1</li>
                    <li>GPS Location Tagging</li>
                    <li>Unlimited Devices</li>
                    <li>Unlimited Departments</li>
                    {/* <li className="na">Pharetra massa</li>
            <li className="na">Massa ultricies mi</li> */}
                  </ul>
                  <div className="btn-wrap">
                    <a href="#" className="btn-buy">
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mt-4 mt-md-0">
                <div
                  className="box recommended"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <span className="recommended-badge">Recommended</span>
                  <h3>Standard</h3>
                  <h4>
                    <sup>$</sup>19<span> / month</span>
                  </h4>
                  <ul>
                    <li>Employees up to 20</li>
                    <li>Administrator up to 2</li>
                    <li>Unlimited Devices</li>
                    <li>Unlimited Departments</li>
                    {/* <li className="na">Massa ultricies mi</li> */}
                  </ul>
                  <div className="btn-wrap">
                    <a href="#" className="btn-buy">
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                <div className="box" data-aos="zoom-in" data-aos-delay="200">
                  <h3>Unlimited</h3>
                  <h4>
                    <sup>$</sup>29<span> / month</span>
                  </h4>
                  <ul>
                    <li>Unlimited Employees</li>
                    <li>Unlimited Administrators</li>
                    <li>Unlimited Devices</li>
                    <li>Unlimited Departments</li>
                  </ul>
                  <div className="btn-wrap">
                    <a href="#" className="btn-buy">
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="faq" className="faq">
          <div className="container">
            <div className="section-title" data-aos="zoom-in">
              <h2>Support</h2>
              <h3>
                Support <span>Center</span>
              </h3>
              <p>
                Send us an email at{" "}
                <a
                  href="mailto: support@mytimetracker.app
"
                >
                  support@mytimetracker.app
                </a>{" "}
                for on-on-one more details.
              </p>
            </div>

            <div className="faq-list">
              <ul>
                <li data-aos="fade-up">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    className="collapse"
                    data-bs-target="#faq-list-1"
                  >
                    What devices do my employees need to use Time Tracker?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-1"
                    className="collapse show"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Your employees could use an Apple or Android device. They
                      could also use Time Tracker from any web browser.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="100">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    className="collapsed"
                  >
                    Can Employees Punch In & Out for Breaks and for Lunch?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Time Tracker supports multiple shifts per day, typically
                      your employees would Punch Out before lunch/breaks and
                      Punch In when they come back.
                    </p>
                    <p>
                      Tracking Breaks
                      <ul>
                        <li className="">
                          If you'd like to keep track of the breaks your
                          employees take, you can setup a new department called
                          "Break". Then assign employees to this departments
                          using the "Members" button in the departments screen
                        </li>

                        <li className="">
                          When employees want to go on break, they would
                          "transfer" into the break department and when they
                          come back, they transfer back to their regular
                          department. This would allow you to track the exact
                          time spent on breaks.
                        </li>
                      </ul>
                    </p>
                    <p>
                      Automatic Time Deductions
                      <ul>
                        <li className="">
                          If you'd like Time Tracker to automatically deduct a
                          lunch break, you can enable automated lunch
                          deductions.
                        </li>
                      </ul>
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    className="collapsed"
                  >
                    Can I use Time Tracker to only track attendance?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      If you only need users to check-in when they arrive, you
                      can set your account to "Attendance-Only" mode. This can
                      be done from the "Settings" page within the Time Tracker
                      site. When your account is set to Attendance-Only, Time
                      Trackeronly tracks when users check-in and doesn't require
                      them to check-out.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    className="collapsed"
                  >
                    Can I use reports for payroll?
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-4"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Yes, you can use either the "Employee Details" report or
                      the "Employee Daily Summary" report and select your pay
                      period's start and end dates
                    </p>
                    <p>
                      Employee Details:
                      <ul>
                        <li className="">
                          This report returns details of every shift worked
                          during the pay period with an option to have a
                          sub-total for each employee.
                        </li>
                      </ul>
                    </p>
                    <p>
                      Employee Daily Summary:
                      <ul>
                        <li className="">
                          You can run this report for either a one-week or a
                          two-week pay period and it returns the total hours
                          worked each day by each employee.
                        </li>
                      </ul>
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="400">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    className="collapsed"
                  >
                    I just added a new employee but they can't punch In.{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      After adding new employees, it may take up to 5 minutes
                      before they can Punch In. If they still can't Punch In
                      after 5 minutes, make sure that your Time Tracker device
                      is connected to the Internet.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <Popup
        screen={"home"}
        body={
          <div className="w-100" style={{ height: "85vh" }}>
            <iframe
              className="video w-100 h-100"
              title="Youtube player"
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src={`https://youtube.com/embed/${yt_ID}?autoplay=0`}
            />
          </div>
        }
      />
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </Screen>
  );
};

export default Home;
