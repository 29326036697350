import { useDispatch } from 'react-redux';
import { firestore as db } from "../Api/firebase.config";

// Redux
import { deleteEmployee, updateEmployee, newEmployee } from '../features/employees/slice';
import { updateCompanies, deleteCompanies, updateSchedule } from '../features/companies/slice';
import { newMessage } from '../features/chat/slice';
import { onSignup } from '../Api/auth';
import api from '../Api/api';

export default () => {
  const dispatch = useDispatch();

  const onChangeStatus = async (company, status) => {
    try {
      await db.collection('Company').doc(company?.companyID).update({ status })
      dispatch(updateCompanies({ ...company, status }))
    } catch (err) {
      console.log(err);
    }
  }
  const onDeleteCompany = async (company) => {
    try {
      await db.collection('Company').doc(company?.companyID).delete();
      await api.delete(`/user?uid=${company?.companyID}`)
      dispatch(deleteCompanies(company))
    } catch (err) {
      console.log(err);
    }
  }
  const onUpdateCompany = async (company) => {
    try {
      await db.collection('Company').doc(company?.companyID).update(company)
      dispatch(updateCompanies(company))
    } catch (err) {
      console.log(err);
    }
  }

  const onUpdateCompanySchedule = async (schedule) => {
    try {
      await db.collection('CompanySchedule').doc(schedule?.companyID).set(schedule);
      dispatch(updateSchedule(schedule))
    } catch (err) {
      console.log(err);
    }
  }
  const onDeleteEmployee = async (employee) => {
    try {
      await db.collection('Users').doc(employee?.userID).delete();
      await api.delete(`/user?uid=${employee?.userID}`)
      dispatch(deleteEmployee(employee))
    } catch (err) {
      console.log(err.response?.data)
      alert(err.message);
    }
  }
  const onUpdateEmployee = async (employee) => {
    try {
      await db.collection('Users').doc(employee?.userID).update(employee);
      dispatch(updateEmployee(employee))
    } catch (err) {
      console.log(err);
    }
  }
  const onCreateEmployee = async (employee, password) => {
    try {
      const res = await onSignup({ email: employee?.email, password }, true)
      const data = { ...employee, userID: res.user.uid };
      await db.collection('Users').doc(data.userID).set(data)
      dispatch(newEmployee(data))
    } catch (err) {
      console.log(err);
    }
  }

  const onSendMessage = async (role, message, isAdminReceiver) => {
    try {
      const first = message.companyID;
      const last =
        role == 'Admin' ? '' :
          isAdminReceiver ? '' : `;${message?.employeeID}`;
      const collection =
        role == 'Admin' ? 'AdminChat' :
          isAdminReceiver ? 'AdminChat' : 'CompanyChat'
      console.log(collection)

      dispatch(newMessage(message))
      await db.collection(collection).doc(`${first}${last};${message?.timeStamp}`).set(message)
    } catch (err) {
      console.log(err);
    }
  }


  return {
    onChangeStatus,
    onDeleteCompany,
    onUpdateCompany,

    onUpdateCompanySchedule,
    onDeleteEmployee,
    onUpdateEmployee,
    onCreateEmployee,

    onSendMessage
  }
}