import React from 'react'

const Form = ({fields}) => {
  return (
    fields?.map((item,index)=>{
      return (
        <div key={index} className="col-12">
          <label htmlFor="inputEmailAddress" className="form-label">{item.label}</label>
          <div className="ms-auto position-relative">
            <div className="position-absolute top-50 translate-middle-y search-icon px-3">{item.icon}</div>
            {
              item.type == 'select'
              ?<>
                <div className="position-absolute top-50 translate-middle-y search-icon px-3"><i className="bx bx-package"></i></div>
                <select onChange={item.setValue} className="form-select form-control radius-30 ps-5" aria-label="Subscription Package">
                  {
                    item.options.map((option,index)=>(
                      <option 
                        key={index}
                        selected={item.value == option} 
                        value={option}
                      >
                        {option}
                      </option>
                    ))
                  }
                </select>
              </>
              :
                <input 
                  className="form-control radius-30 ps-5" 
                  value={item.value} 
                  placeholder={item.placeholder}
                  type={item.type ? item.type : 'text'} 
                  id={item.id} 
                  onChange={item.setValue}
                />
            }
          </div>
        </div>
      )
    })
  )
}

export default Form