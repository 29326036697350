import React from 'react'
import Header from './Header'
import Navbar from './Navbar'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'


const Screen = ({ children }) => {
  const location = useLocation();
  const { token } = useSelector(state => state.auth);

  // following routes a role can not access

  const owner = [
    '/companies',
    '/company_profile',
  ]

  const admin = [
    '/schedule',
    '/employees',
    '/new_employee',
    '/employee_profile',
  ]

  const access = token?.occupation == 'Owner'
    ? !owner.includes(location.pathname)
    : !admin.includes(location.pathname)

  return (
    !token ? <Navigate to={'/login'} /> :
      !access ? <Navigate to={'*'} />
        :
        <>
          <div className="wrapper">
            <Header />
            <Navbar />
            {children}
            <div className="overlay nav-toggle-icon"></div>
          </div>
        </>
  )
}

export default Screen