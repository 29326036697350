import React from 'react'

const Form = ({title, fields}) => {
  return (
    <div className="card shadow-none border">
      <div className="card-body">
        {/* Title */}
        <h6 className="mb-0 text-uppercase">{title}</h6>
        <hr/>
        <div className="row">
          {
            fields?.map((item,index)=>{
              return (
                <div key={index} className={`col-12 col-md-${item.full ? '12' : '6'} mb-3`}>
                  <label className="form-label">{item.label}:</label>
                  {
                    item.long 
                    ? 
                      <textarea 
                        className="form-control" 
                        rows="4" 
                        cols="4" 
                        placeholder={item.placeholder}
                        onChange={(e)=>item.setValue(e.target.value)} 
                        type={"text"} 
                        value={item.value}
                      />
                    :
                    item.options 
                    ?
                      <select value={item.value} onChange={(e)=>item.setValue(e.target.value)} className="form-select" aria-label="Default select example">
                        {
                          item.options.map((option,index)=>(
                            <option 
                              key={index}
                              selected={item.value == option} 
                              value={option}
                            >
                              {option}
                            </option>
                          ))
                        }
                      </select>
                    :
                      <input 
                        id={item.id} 
                        placeholder={item.placeholder}
                        onChange={(e)=>item.setValue(e.target.value)} 
                        type={item.type ? item.type : "text"} 
                        className="form-control" 
                        value={item.value}
                        disabled={item.disabled}
                      />
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Form