import firebase from "firebase/compat/app";
import { getStorage } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBhzjrhdKcUxsnj_MedtjSQzHPH5-X-r8c",
  authDomain: "time-tracker-120e9.firebaseapp.com",
  projectId: "time-tracker-120e9",
  storageBucket: "time-tracker-120e9.appspot.com",
  messagingSenderId: "1079406942792",
  appId: "1:1079406942792:web:2c5b6216406ff2896d567d",
  measurementId: "G-ET7XDYYZ1P"
};
const app = firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const storage = getStorage(app);

export default firebase;