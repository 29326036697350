import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { handleShow } from '../../features/popup/slice';

import Screen from '../../Components/Screen'
import BreadCrumb from '../../Components/BreadCrumb'
import Form from './Form'
import useUpdateData from '../../Hooks/useUpdateData'
import Popup from '../../Components/Popup';

const randomPassword = () => {
  return Math.random().toString(36).slice(2, 12)
}

const NewEmployee = () => {

  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);

  const { onCreateEmployee } = useUpdateData();

  const [loading, setLoading] = useState();
  const [password, setPassword] = useState(randomPassword());
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    earningPerHour: '',
    shift: 'day'
  });

  const fields = [
    {
      id: 'inputFirstName',
      label: 'First Name',
      placeholder: "Enter First Name",
      value: data.first_name,
      setValue: (value) => setData({ ...data, first_name: value })
    },
    {
      id: 'inputLastName',
      label: 'Last Name',
      placeholder: "Enter Last Name",
      value: data.last_name,
      setValue: (value) => setData({ ...data, last_name: value })
    },
    {
      id: 'inputEmailAddress',
      label: 'Email Address',
      placeholder: "Enter Email Address",
      value: data.email,
      setValue: (value) => setData({ ...data, email: value })
    },
    {
      id: 'inputPhoneNumber',
      label: 'Phone Number',
      placeholder: "Enter Phone Number",
      value: data.phone,
      setValue: (value) => setData({ ...data, phone: value })
    },
    {
      id: 'inputShift',
      label: 'Shift',
      placeholder: "Select Working Shift",
      value: data.shift,
      setValue: (value) => setData({ ...data, shift: value })
    },
    {
      id: 'inputHourlyRate',
      label: 'Hourly Rate',
      placeholder: "Enter Hourly Rate",
      value: data.earningPerHour,
      setValue: (value) => setData({ ...data, earningPerHour: parseFloat(value) }),
      type: 'number'
    },
    {
      id: 'inputPassword',
      label: 'Password',
      value: password,
      setValue: () => setPassword(randomPassword()),
      disabled: true,
    },
  ]

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      const send = {
        name: `${data.first_name} ${data.last_name}`,
        email: data.email,
        phone: data.phone,
        earningPerHour: data.earningPerHour,
        companyID: token?.companyID,
        shift: data.shift
      }
      setLoading(true);
      await onCreateEmployee(send, password);
      setData({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        earningPerHour: ''
      });
      setPassword(randomPassword())
      dispatch(handleShow({ screen: 'new_employee', title: 'New Employee added' }))
      setLoading(false);
    } catch (err) {
      dispatch(handleShow({ screen: 'new_employee', title: 'New Employee could not be added' }))
      setLoading(false);
      console.log(err);
    }
  }

  return (
    <Screen>
      <main className="page-content">
        <BreadCrumb flow={[
          { label: 'New Employee', to: '/new_employee' },
          { label: 'New Employee', to: '/new_employee' },
        ]} />
        <div className="row">
          <div className="col-12">
            <form onSubmit={onSubmit} action="" method="">
              {/* Form */}
              <Form title={'Add New Employee'} fields={fields} />
              {/* Submit Button */}
              <div className="text-start">
                <button type="submit" className="btn btn-primary px-4">Add Employee</button>
              </div>
            </form>
          </div>
        </div>
      </main>
      <Popup screen={'new_employee'} />
    </Screen>
  )
}

export default NewEmployee