import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Static
import Home from "./Pages/Static/Home";
import Privacy from "./Pages/Static/Privacy";
import Terms from "./Pages/Static/Terms";

// Unauthorized
import Unauthorized from "./Pages/Unauthorized";

// Authentication
import Login from "./Pages/Auth/Login";
import Signup from "./Pages/Auth/Signup";
import Forgot from "./Pages/Auth/Forgot";
import Reset from "./Pages/Auth/Reset";

// Home Page
import Dashboard from "./Pages/Dashboard/Dashboard";

// Company Pages
import Companies from "./Pages/Companies/Companies";
import CompanyProfile from "./Pages/CompanyProfile/CompanyProfile";
import Schedule from "./Pages/Schedule/Schedule";

// Employee Pages
import Employees from "./Pages/Employees/Employees";
import EmployeeProfile from "./Pages/EmployeeProfile/EmployeeProfile";
import NewEmployee from "./Pages/NewEmployee/NewEmployee";

// Convo Pages
import Conversation from "./Pages/Conversation/Conversation";

import { onLocalSignIn } from "./Api/auth";
import { login } from "./features/auth/slice";

function App() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);

  const tryLocalSignIn = async () => {
    try {
      setLoading(true);
      const token = await onLocalSignIn();
      dispatch(login(token));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    tryLocalSignIn();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />

      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot" element={<Forgot />} />

      <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/companies" element={<Companies />} />
      <Route path="/company_profile" element={<CompanyProfile />} />
      <Route path="/schedule" element={<Schedule />} />

      <Route path="/employees" element={<Employees />} />
      <Route path="/new_employee" element={<NewEmployee />} />
      <Route path="/employee_profile" element={<EmployeeProfile />} />

      <Route path="/conversation" element={<Conversation />} />
      <Route path="*" element={<Unauthorized />} />
    </Routes>
  );
}

export default App;
