import "./assets/css/style.css";

import React from "react";
import { useLocation, Link } from "react-router-dom";

import { useSelector } from "react-redux";
import useSidebar from "../../Hooks/useSidebar";
import Sidebar from "./Sidebar";

const Header = () => {
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  const { show, hideSidebar, showSidebar } = useSidebar()
  return (
    <div>
      <section id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope d-flex align-items-center">
              <a href="mailto:support@timetracker.com">
                support@mytimetracker.app
              </a>
            </i>
            <i className="bi bi-phone d-flex align-items-center ms-4">
              <span>+1 5589 55488 55</span>
            </i>
          </div>
          <div className="social-links d-none d-md-flex align-items-center">
            <a href="#" className="twitter">
              <i className="bi bi-twitter"></i>
            </a>
            <a href="#" className="facebook">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="#" className="instagram">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="#" className="linkedin">
              <i className="bi bi-linkedin"></i>
            </a>
          </div>
        </div>
      </section>

      <Sidebar />

      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex justify-content-between">
          <div className="logo">
            <Link to="/">
              <img src="assets/img/Logo_TT.svg" alt="" className="img-fluid" />
            </Link>
          </div>

          <nav id="navbar" className="navbar">
            <i className={`bi bi-${show ? 'x' : 'list'} mobile-nav-toggle`} onClick={show ? hideSidebar : showSidebar}></i>
            <ul className="dropdown">
              <li>
                <a className="nav-link scrollto active" href="#hero">
                  {location.pathname.toLocaleUpperCase().substring(1)}
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/#about">
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/#services">
                  Services
                </a>
              </li>
              <li>
                <a className="nav-link scrollto " href="/#why-us">
                  How it works?
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/#pricing">
                  Pricing
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/#faq">
                  Support
                </a>
              </li>
              <li>
                <a className="nav-link getstarted" href={token ? "/dashboard" : "/login"}>
                  {token ? "Dashboard" : "Login"}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header >
    </div >
  );
};

export default Header;
