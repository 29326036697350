import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const ChatList = ({ chats, search, selectedChat, onSelect }) => {
  const { messages } = useSelector(state => state.chat);
  const { token } = useSelector(state => state.auth);

  const chatId = `${token?.occupation == 'Admin' ? 'company' : 'user'}ID`;
  const messageId = `${token?.occupation == 'Admin' ? 'company' : 'employee'}ID`;
  return (
    chats
      // Filter Search
      ?.filter(item => search ? item.name.toLowerCase().includes(search.toLowerCase()) : true)
      // add last message in the item
      .map(item => {
        const chatMessages = messages?.filter(m => m[messageId] == item[chatId]);
        const lastMessage = chatMessages[chatMessages?.length - 1];
        return { ...item, lastMessage }
      })
      // dont show if 
      .filter(item => item.lastMessage || (selectedChat ? selectedChat[chatId] == item[chatId] : false))
      // sort on last message
      .sort((a, b) => {
        const diff = b.lastMessage?.timeStamp - a.lastMessage?.timeStamp;
        return isNaN(diff) ? -1 : diff
      })
      // display
      .map((item, index) => {
        const selected = item.id == selectedChat?.id;


        const lastMessage = item?.lastMessage;
        const message = lastMessage ? lastMessage.message : '';
        const timestamp = lastMessage ? lastMessage.timeStamp : 0;

        const timeArray = timestamp ? new Date(timestamp).toLocaleTimeString().split(':') : null;
        const ampm = timeArray[2].split(' ')[1]
        const time = timeArray ? `${timeArray[0]}:${timeArray[1]} ${ampm ? ampm : ''}` : null;

        return (
          <a key={index} onClick={() => onSelect(item)} className={`list-group-item ${selected ? 'active' : ''}`}>
            <div className="d-flex">
              <div className="chat-user-online">
                <img src={item.imageURL} className="rounded-circle" alt="" width="42" height="42" />
              </div>
              <div className="flex-grow-1 ms-2">
                <h6 className="mb-0 chat-title">{item.name}</h6>
                <p className="mb-0 chat-msg">{message}</p>
              </div>
              <div className="chat-time">{time}</div>
            </div>
          </a>
        )
      })
  )
}

const Sidebar = ({ selectedChat, onSelect }) => {
  const location = useLocation();
  const { chats } = useSelector(state => state.chat);
  const { token } = useSelector(state => state.auth);

  const [search, setSearch] = useState('');

  return (
    <div className="chat-sidebar">
      {/* Header */}
      <div className="chat-sidebar-header">
        <div className="d-flex align-items-center">
          <div className="chat-user-online">
            <img src={token?.imageURL} width="45" height="45" className="rounded-circle" alt="" />
          </div>
          <div className="flex-grow-1 ms-2">
            <p className="mb-0">{token?.name}</p>
          </div>
        </div>
        <div className="mb-3"></div>
        <div className="input-group input-group-sm"> <span className="input-group-text bg-transparent"><i className='bx bx-search'></i></span>
          <input value={search} onChange={(e) => setSearch(e.target.value)} type="text" className="form-control" placeholder="Search" />
        </div>
      </div>
      {/* Body */}
      <div className="chat-sidebar-content" style={{ overflow: 'scroll', overflowX: 'hidden' }}>
        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-Chats">
            <div className="chat-list ps ps--active-y">
              <div className="list-group list-group-flush">
                <ChatList
                  chats={chats
                    // see if chatting with admin, then allow those users to show
                    ?.filter(chat => location?.state?.isAdmin ? chat.isAdmin : !chat.isAdmin)
                  }
                  search={search}
                  selectedChat={selectedChat}
                  onSelect={onSelect}
                />
              </div>
              <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                <div className="ps__thumb-x" tabindex="0" style={{ left: 0, width: 0 }}></div>
              </div>
              <div class="ps__rail-y" style={{ top: 0, height: "100%", right: 0 }}>
                <div class="ps__thumb-y" tabindex="0" style={{ top: 0, height: 167, }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar