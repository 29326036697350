import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { logout } from '../features/auth/slice';

const Option = ({ option }) => (
  <NavDropdown.Item onClick={option?.onClick}>
    <div className="dropdown-item" >
      <div className="d-flex align-items-center">
        <div className="">{option?.icon}</div>
        <NavDropdown.ItemText>{option?.title}</NavDropdown.ItemText>
      </div>
    </div>
  </NavDropdown.Item>
)

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector(state => state.auth);

  const options = [
    {
      icon: <i className="bi bi-person-fill"></i>,
      // onClick: () => navigate('/profile'),
      title: 'Profile'
    },
    {
      icon: <i className="bi bi-gear-fill"></i>,
      onClick: () => navigate('/profile'),
      title: 'Settings'
    },
    {
      icon: <i className="bi bi-person-fill"></i>,
      onClick: () => navigate('/profile'),
      title: 'Dashboard'
    },
    {
      icon: <i className="bi bi-speedometer"></i>,
      onClick: () => navigate('/profile'),
      title: 'Earning'
    },
    {
      icon: <i className="bi bi-cloud-arrow-down-fill"></i>,
      onClick: () => navigate('/profile'),
      title: 'Downloads'
    },
  ]
  const image = token?.imageURL ? token?.imageURL : require('../Assets/images/avatars/avatar-1.png')
  return (
    <header className="top-header">
      <nav class="navbar navbar-expand gap-3 align-items-center">
        <div class="mobile-toggle-icon fs-3">
          <i class="bi bi-list"></i>
        </div>
        <div className="ms-auto">
          <ul className="d-flex align-items-center">
            <NavDropdown
              id="nav-dropdown-dark-example"
              title={<img src={image} className="user-img" alt="" />}
            >
              <NavDropdown.Item >
                <div className="dropdown-item">
                  <div className="d-flex align-items-center">
                    <img src={image} alt="" className="rounded-circle" width="54" height="54" />
                    <div className="ms-3">
                      <h6 className="mb-0 dropdown-user-name">{token?.name}</h6>
                      <small className="mb-0 dropdown-user-designation text-secondary">{token?.occupation}</small>
                    </div>
                  </div>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <Option option={{
                title: 'Logout',
                icon: <i className="bi bi-lock-fill"></i>,
                onClick: () => { navigate('/'); dispatch(logout()) }
              }} />
            </NavDropdown>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header