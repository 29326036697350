import { firestore as db } from "./firebase.config";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth";

export const auth = getAuth();

const getDBToken = async (user) => {
  // Check if SUPER ADMIN
  const adminResponse =
    (
      await db
        .collection('Admin')
        .where('userID', '==', user.uid)
        .get()
    ).docs[0];
  if (adminResponse?.exists) {
    return { ...adminResponse.data(), occupation: 'Admin' }
  }

  // check if COMPANY
  const companyResponse =
    (
      await db
        .collection('Company')
        .where('companyID', '==', user.uid)
        .get()
    ).docs[0];
  if (companyResponse?.exists) {
    return { ...companyResponse.data(), occupation: 'Owner' }
  }
  return null;
}

export const onLogin = async (credentials, remember) => {
  const authResponse = await signInWithEmailAndPassword(auth, credentials.email, credentials.password);
  const token = await getDBToken(authResponse.user);
  if (!token) throw Error('Incorrect Email or Password.')
  if (remember)
    localStorage.setItem('token', JSON.stringify(token));
  return token
}

export const onSignup = async (credentials, employee) => {
  const authResponse = await createUserWithEmailAndPassword(auth, credentials.email, credentials.password);

  if (employee) return authResponse;

  const token = {
    companyID: authResponse.user.uid,
    email: credentials.email,
    name: credentials.name,
    admin: credentials.admin,
    package: credentials.package,
    status: false
  };
  // store in db
  db.collection('Company').doc(authResponse.user.uid).set(token)
  localStorage.setItem('token', JSON.stringify({ ...token, occupation: 'Owner' }));

  return { ...token, occupation: 'Owner' };
}

export const onLocalSignIn = async () => {
  try {
    const token = await localStorage.getItem('token');
    return JSON.parse(token);
  } catch (err) {
    console.log(err);
  }
}

export const onLogout = async () => {
  await signOut(auth)
}