import React from 'react'

const ProfileCard = ({company, onShow}) => {
  return (
    <div className="card shadow-sm border-0 overflow-hidden">
      <div className="card-body">
        {/* Proflie Photo */}
        <div className="profile-avatar text-center" onClick={onShow}>
          <img src={company?.imageURL ? company?.imageURL : require('../../Assets/images/avatars/avatar-1.png')} className="rounded-circle shadow" width="120" height="120" alt=""/>
        </div>
        {/* Row Data */}
        <div className="d-flex align-items-center justify-content-around mt-5 gap-3">
          <div className="text-center">
            <h4 className="mb-0">{company?.employees}</h4>
            <p className="mb-0 text-secondary">N.o of Employees</p>
          </div>
          <div className="text-center">
            <h4 className="mb-0">{company?.days}</h4>
            <p className="mb-0 text-secondary">Working Days</p>
          </div>
          <div className="text-center">
            <h4 className="mb-0">{company?.status ? 'Active' : 'UnActive'}</h4>
            <p className="mb-0 text-secondary">Status</p>
          </div>
        </div>
        {/* Name */}
        <div className="text-center mt-4">
          <h4 className="mb-1">{company?.name ? company?.name : ''}</h4>
          <p className="mb-0 text-secondary">{company?.city}, {company?.country}</p>
          <div className="mt-4"></div>
        </div>
        <hr/>
        {/* About */}
        <div className="text-start">
          <h5 className="">About</h5>
          <p className="mb-0">{company?.about ? company?.about : ''}</p>
        </div>
      </div>
    </div>
  )
}

export default ProfileCard