import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import authReducer from '../features/auth/slice';
import employeesReducer from '../features/employees/slice';
import companiesReducer from '../features/companies/slice';
import chatReducer from '../features/chat/slice';
import popupReducer from '../features/popup/slice';
import sidebarReducer from '../features/home/slice';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  auth: authReducer,
  employees: employeesReducer,
  companies: companiesReducer,
  chat: chatReducer,
  popup: popupReducer,
  sidebar: sidebarReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})

export const persistor = persistStore(store);