import React from "react";

const Hero = () => {
  return (
    <section id="hero">
      <div className="hero-container" data-aos="fade-up">
        <h1>Welcome to TimeTracker</h1>
        <h2>
          Turn any smartphone or tablet into a cloud-based time & attendance
          system.
        </h2>
        <div className="row">
          <div className="col-12 col-md-6">
            <a
              href="https://play.google.com/store/apps/details?id=com.timetracker2023"
              target="_blank"
            >
              <img
                src="assets/img/google-play.svg"
                alt="Download for Android"
                width="150"
              />
            </a>
          </div>
          <div className="col-12 col-md-6">
            <a href="#">
              <img
                src="assets/img/app-store.svg"
                alt="Download for iOS"
                width="150"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
